import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  DocumentIcon as DocumentHeroIcon,
  XMarkIcon,
  CloudArrowDownIcon,
  PencilIcon,
  CheckCircleIcon,
  ArchiveBoxIcon,
  TrashIcon,
  PlayIcon,
  CursorArrowRaysIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import { formatDistanceToNow } from 'date-fns';
import { documentService } from '../../../services/documentService';
import { noteService } from '../../../services/noteService';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserContext } from '../../../App';
import { useState, useEffect, useRef, useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ProjectNotePage from '../../Pages/ProjectPages/ProjectNotePage';

// Add these color configurations at the top of the file
const urgencyColors = {
  low: 'bg-blue-50 text-blue-600 border-blue-200',
  normal: 'bg-green-50 text-green-600 border-green-200',
  high: 'bg-yellow-50 text-yellow-600 border-yellow-200',
  urgent: 'bg-red-50 text-red-600 border-red-200',
};

const statusColors = {
  open: 'bg-yellow-50 text-yellow-600 border-yellow-200',
  in_progress: 'bg-blue-50 text-blue-600 border-blue-200',
  resolved: 'bg-green-50 text-green-600 border-green-200',
  archived: 'bg-gray-50 text-gray-600 border-gray-200',
};

const typeColors = {
  change: 'bg-yellow-100 text-yellow-800 border-yellow-200',
  design: 'bg-purple-100 text-purple-800 border-purple-200',
  general: 'bg-gray-100 text-gray-800 border-gray-200',
};

// Add this helper function to check if a document is an Office file
const isOfficeFile = (document) => {
  const key = document.key?.toLowerCase() || '';
  return key.match(/\.(doc|docx|ppt|pptx|xls|xlsx)$/);
};

// Add this helper function to check if a document is an Enscape file
const isEnscapeFile = (document) => {
  return document.key?.toLowerCase().endsWith('.exe') || document.signedUrl?.includes('enscape');
};

// Add this helper function at the top of the file
const formatDate = (dateString) => {
  if (!dateString) return 'Unknown date';

  try {
    const date = new Date(dateString);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }
    return formatDistanceToNow(date, {
      addSuffix: true,
      includeSeconds: true,
    });
  } catch (error) {
    console.warn('Error formatting date:', error);
    return 'Invalid date';
  }
};

const DocumentViewerModal = ({ document: initialDocument, isOpen, onClose, onDocumentUpdate, userRole }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState(initialDocument);
  const { projectId } = useParams();
  const { userContextData } = useContext(UserContext);
  const navigate = useNavigate();
  const enscapeIframeRef = useRef(null);
  const [instructionsButtonClicked, setInstructionsButtonClicked] = useState(false);
  const [showNotes, setShowNotes] = useState(true);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [showNoteEditor, setShowNoteEditor] = useState(false);

  console.log('document', document);
  console.log('userRole', userRole);

  useEffect(() => {
    setDocument(initialDocument);
  }, [initialDocument]);

  // Move handlers to component level
  const handleMouseEnter = () => {
    if (enscapeIframeRef.current) {
      enscapeIframeRef.current.focus();
    }
  };

  const handleIframeClick = () => {
    if (enscapeIframeRef.current && document.pointerLockElement !== enscapeIframeRef.current) {
      try {
        enscapeIframeRef.current.requestPointerLock();
      } catch (err) {
        console.log('Pointer lock not available:', err);
      }
    }
  };

  // Modify getViewerContent to use the ref and handlers from component scope
  const getViewerContent = () => {
    if (!document) return null;

    // Check if document is an Enscape file
    if (isEnscapeFile(document)) {
      return (
        <div className="h-full w-full relative" onMouseEnter={handleMouseEnter}>
          <iframe
            ref={enscapeIframeRef}
            src={document.signedUrl}
            className="w-full h-full"
            style={{
              border: 0,
              pointerEvents: 'all',
            }}
            scrolling="no"
            loading="lazy"
            allow="fullscreen; xr-spatial-tracking; gamepad; keyboard-map"
            allowFullScreen
            referrerPolicy="strict-origin"
            sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals allow-pointer-lock allow-presentation"
            onClick={handleIframeClick}
            onLoad={() => {
              if (enscapeIframeRef.current) {
                enscapeIframeRef.current.focus();
              }
            }}
          />
          <div className="absolute inset-0 pointer-events-none opacity-0 hover:opacity-100 transition-opacity" aria-hidden="true">
            <div className="absolute bottom-4 left-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-lg text-sm">Click to interact</div>
          </div>
        </div>
      );
    }

    // Handle Office documents (Word, PowerPoint, Excel) with Google Docs Viewer
    if (isOfficeFile(document)) {
      // Create Google Docs Viewer URL
      const googleDocsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(document.signedUrl)}&embedded=true`;

      console.log('googleDocsViewerUrl', googleDocsViewerUrl);

      return (
        <div className="h-full w-full">
          <iframe src={googleDocsViewerUrl} className="h-full w-full" title={document.name} frameBorder="0" loading="lazy" />
          <div className="hidden">
            <a href={document.signedUrl} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-600 hover:text-blue-800">
              Download file
            </a>
          </div>
        </div>
      );
    }

    // Default document viewer with mobile optimization
    return (
      <div className="h-full w-full">
        <iframe src={document.signedUrl} className="h-full w-full" title={document.name} allow="fullscreen" allowFullScreen loading="lazy" />
        <object data={document.signedUrl} type="application/pdf" className="h-full w-full hidden md:block">
          <a
            href={document.signedUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flex h-full items-center justify-center p-4 text-center text-gray-500"
          >
            Click to view document
          </a>
        </object>
      </div>
    );
  };

  const handleApprove = async () => {
    try {
      setIsLoading(true);
      await documentService.updateDocument(projectId, document._id, {
        approvedByClient: true,
      });
      const updatedDoc = { ...document, approvedByClient: true };
      setDocument(updatedDoc);
      onDocumentUpdate(updatedDoc);
      toast.success('Document approved successfully');
    } catch (error) {
      toast.error('Failed to approve document');
      console.error('Error approving document:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleArchive = async () => {
    try {
      setIsLoading(true);
      const updatedDoc = {
        ...document,
        subCategory: 'Archived',
        isArchived: true,
        originalSubCategory: document.subCategory,
      };
      await documentService.updateDocument(projectId, document._id, {
        subCategory: 'Archived',
        isArchived: true,
        originalSubCategory: document.subCategory,
      });
      onDocumentUpdate(updatedDoc);
      toast.success('Document archived successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to archive document');
      console.error('Error archiving document:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this document?')) return;

    try {
      setIsLoading(true);
      await documentService.deleteDocument(projectId, document._id, userContextData);
      onDocumentUpdate({ type: 'delete', documentId: document._id });
      toast.success('Document deleted successfully');
      onClose();
    } catch (error) {
      toast.error('Failed to delete document');
      console.error('Error deleting document:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNote = async () => {
    try {
      setIsLoading(true);
      // Create a draft note linked to this document
      const draftNote = await noteService.createDraftNote(projectId, userContextData.user_id, {
        title: document.name,
        content: 'Add your feedback or change request here.',
        type: 'general',
        urgency: 'normal',
        status: 'open',
        statusHistory: [],
        actionItems: [],
        relatedDocument: document._id,
        documentName: document.name || document.originalName || 'Unnamed Document',
      });

      // Show the note editor with the new draft note
      setSelectedNoteId(draftNote._id);
      setShowNoteEditor(true);
    } catch (error) {
      console.error('Error creating draft note:', error);
      toast.error('Failed to create new note');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendApprovalReminder = async () => {
    try {
      setIsLoading(true);
      await documentService.sendApprovalReminder(projectId, document._id);
      toast.success('Approval reminder email sent successfully', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      console.error('Error sending approval reminder:', error);
      toast.error('Failed to send approval reminder email', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Simplified note selection handler
  const handleNoteSelect = (noteId) => {
    setSelectedNoteId(noteId);
    setShowNoteEditor(true);
  };

  // Add a function to handle closing the note editor
  const handleCloseNoteEditor = () => {
    setShowNoteEditor(false);
    setSelectedNoteId(null);
  };

  // Add a function to handle note updates
  const handleNoteUpdate = async (updatedNote) => {
    try {
      // If the document's notes array exists, update it
      if (document.notes) {
        const noteIndex = document.notes.findIndex((note) => note._id === updatedNote._id);

        if (noteIndex >= 0) {
          // Update existing note
          const updatedNotes = [...document.notes];
          updatedNotes[noteIndex] = updatedNote;
          const updatedDoc = { ...document, notes: updatedNotes };
          setDocument(updatedDoc);
          if (onDocumentUpdate) onDocumentUpdate(updatedDoc);
        } else {
          // Add new note to the array
          const updatedNotes = [...document.notes, updatedNote];
          const updatedDoc = { ...document, notes: updatedNotes };
          setDocument(updatedDoc);
          if (onDocumentUpdate) onDocumentUpdate(updatedDoc);
        }
      } else {
        // If no notes array exists yet, create one
        const updatedDoc = { ...document, notes: [updatedNote] };
        setDocument(updatedDoc);
        if (onDocumentUpdate) onDocumentUpdate(updatedDoc);
      }

      // Close the note editor if it was a new note that was just saved
      if (updatedNote.isDraft === false && selectedNoteId === updatedNote._id) {
        setShowNoteEditor(false);
        setSelectedNoteId(null);

        // Refresh the document data to ensure we have the latest notes
        try {
          const refreshedDoc = await documentService.getDocument(projectId, document._id);
          if (refreshedDoc) {
            setDocument(refreshedDoc);
            if (onDocumentUpdate) onDocumentUpdate(refreshedDoc);
          }
        } catch (error) {
          console.error('Error refreshing document data:', error);
        }
      }
    } catch (error) {
      console.error('Error updating document notes:', error);
    }
  };

  // Add a function to handle note deletion
  const handleNoteDelete = async (deletedNoteId) => {
    try {
      // If the document's notes array exists, remove the deleted note
      if (document.notes) {
        const updatedNotes = document.notes.filter((note) => note._id !== deletedNoteId);
        const updatedDoc = { ...document, notes: updatedNotes };
        setDocument(updatedDoc);
        if (onDocumentUpdate) onDocumentUpdate(updatedDoc);
      }

      // Close the note editor
      setShowNoteEditor(false);
      setSelectedNoteId(null);

      // Refresh the document data to ensure we have the latest notes
      try {
        const refreshedDoc = await documentService.getDocument(projectId, document._id);
        if (refreshedDoc) {
          setDocument(refreshedDoc);
          if (onDocumentUpdate) onDocumentUpdate(refreshedDoc);
        }
      } catch (error) {
        console.error('Error refreshing document data:', error);
      }
    } catch (error) {
      console.error('Error handling note deletion:', error);
    }
  };

  if (!document) return null;

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center p-0 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="flex w-full max-w-full transform flex-col overflow-hidden bg-white shadow-2xl transition-all h-screen sm:rounded-none md:rounded-none">
                <div className="flex flex-col h-full">
                  {/* Header - Improved spacing and alignment */}
                  <div className="px-4 py-3 sm:px-6 sm:py-4 border-b border-gray-200">
                    <div className="flex items-center justify-between">
                      <div className="flex-1 min-w-0 pr-2">
                        <div className="flex items-start">
                          <DocumentHeroIcon className="h-5 w-5 sm:h-6 sm:w-6 text-gray-400 mt-1 flex-shrink-0 mr-3" />
                          <div className="min-w-0 flex-1">
                            <Dialog.Title className="text-base sm:text-lg font-medium text-gray-900 text-left truncate">{document.name}</Dialog.Title>
                            <div className="mt-1 flex flex-wrap items-center gap-x-2 text-xs text-gray-500">
                              <span className="truncate">Uploaded by {document.uploadedBy?.email || 'Unknown'}</span>
                              <span className="hidden xs:inline-block">•</span>
                              <span>Version {document.version || '1.0'}</span>
                              <span className="hidden xs:inline-block">•</span>
                              <span>{formatDate(document.uploadedDate)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2 sm:space-x-3 flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => window.open(document.signedUrl, '_blank')}
                          className="hidden sm:inline-flex items-center rounded-lg px-3 py-1.5 text-sm font-medium text-gray-700 hover:bg-gray-100 border border-gray-200"
                        >
                          <CloudArrowDownIcon className="mr-1.5 h-4 w-4" />
                          Download
                        </button>
                        {/* Mobile download button */}
                        <button
                          type="button"
                          onClick={() => window.open(document.signedUrl, '_blank')}
                          className="sm:hidden inline-flex items-center rounded-lg p-1.5 text-gray-700 hover:bg-gray-100 border border-gray-200"
                        >
                          <CloudArrowDownIcon className="h-5 w-5" />
                        </button>
                        <button
                          type="button"
                          onClick={onClose}
                          className="rounded-lg p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-500 border border-transparent hover:border-gray-200"
                        >
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Main content area with document viewer and sidebar - Improved proportions */}
                  <div className="flex flex-col lg:flex-row flex-1 overflow-hidden">
                    {/* Document viewer or Note editor based on state */}
                    {showNoteEditor ? (
                      <div className="flex-1 overflow-auto bg-white h-[50vh] lg:h-auto">
                        <div className="p-3 sm:p-4 flex justify-between items-center border-b border-gray-200">
                          <h2 className="text-base sm:text-lg font-medium text-gray-900 tracking-tight">
                            {selectedNoteId ? (selectedNoteId === 'new' ? 'New Note' : 'Note Details') : 'Note'}
                          </h2>
                          <button
                            onClick={handleCloseNoteEditor}
                            className="rounded-lg p-1.5 text-gray-400 hover:bg-gray-100 hover:text-gray-500 border border-transparent hover:border-gray-200"
                          >
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                        <div className="p-4 text-left">
                          <ProjectNotePage
                            noteId={selectedNoteId}
                            projectId={projectId}
                            embedded={true}
                            onClose={handleCloseNoteEditor}
                            onNoteUpdate={handleNoteUpdate}
                            onNoteDelete={handleNoteDelete}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex-1 overflow-auto bg-gray-50 h-[50vh] lg:h-auto">{getViewerContent()}</div>
                    )}

                    {/* Sidebar - Improved with consistent spacing */}
                    <div className="w-full lg:w-80 xl:w-96 overflow-y-auto border-t lg:border-t-0 lg:border-l border-gray-200 bg-white flex-shrink-0">
                      <div className="p-4 space-y-6">
                        {/* Related Notes - Only show if notes exist */}
                        {document.notes && document.notes.length > 0 && (
                          <div className="text-left">
                            <div className="flex items-center justify-between mb-3">
                              <h3 className="text-sm font-medium text-gray-900 flex items-center">
                                Related Notes
                                <span className="ml-2 bg-gray-100 text-gray-700 text-xs font-medium px-2 py-0.5 rounded-full">{document.notes.length}</span>
                              </h3>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowNotes(!showNotes);
                                }}
                                className="inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-gray-500 hover:text-gray-900 rounded-md hover:bg-gray-50 transition-colors border border-transparent hover:border-gray-200"
                              >
                                {showNotes ? (
                                  <>
                                    Hide
                                    <ChevronUpIcon className="h-3.5 w-3.5" />
                                  </>
                                ) : (
                                  <>
                                    Show
                                    <ChevronDownIcon className="h-3.5 w-3.5" />
                                  </>
                                )}
                              </button>
                            </div>

                            <AnimatePresence>
                              {showNotes && (
                                <motion.div
                                  initial={{ opacity: 0, height: 0 }}
                                  animate={{ opacity: 1, height: 'auto' }}
                                  exit={{ opacity: 0, height: 0 }}
                                  transition={{ duration: 0.2 }}
                                  className="overflow-hidden"
                                >
                                  <div className="space-y-3 max-h-[40vh] lg:max-h-[30vh] overflow-y-auto pr-1">
                                    {document.notes.map((note) => (
                                      <button
                                        key={note._id}
                                        onClick={() => handleNoteSelect(note._id)}
                                        className="w-full text-left p-3 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
                                      >
                                        {/* Type Badge at the top */}
                                        <div className="mb-2">
                                          <span
                                            className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium border ${
                                              typeColors[note.type || 'general']
                                            }`}
                                          >
                                            {note.type === 'change' ? 'Change Request' : note.type === 'design' ? 'Design Note' : 'General Note'}
                                          </span>
                                        </div>

                                        {/* Title and Content */}
                                        <div className="flex-1 min-w-0">
                                          <p className="text-sm font-medium text-gray-900 truncate">{note.title}</p>
                                          <p className="text-xs text-gray-500 mt-1 line-clamp-2">{note.content}</p>
                                        </div>

                                        {/* Author and Date */}
                                        <div className="mt-2 flex items-center text-xs text-gray-500">
                                          <span className="truncate">{note.authorId.name || note.authorEmail}</span>
                                          <span className="mx-1">•</span>
                                          <span>{formatDate(note.timestamp)}</span>
                                        </div>

                                        {/* Status and Urgency Labels */}
                                        <div className="mt-2 flex items-center gap-2">
                                          <span
                                            className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium border ${
                                              statusColors[note.status || 'open']
                                            }`}
                                          >
                                            {(note.status || 'open').charAt(0).toUpperCase() + (note.status || 'open').slice(1).replace('_', ' ')}
                                          </span>
                                          <span
                                            className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium border ${
                                              urgencyColors[note.urgency || 'normal']
                                            }`}
                                          >
                                            {(note.urgency || 'normal').charAt(0).toUpperCase() + (note.urgency || 'normal').slice(1)}
                                          </span>
                                        </div>
                                      </button>
                                    ))}
                                  </div>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </div>
                        )}

                        {/* Actions - Improved consistency and sizing */}
                        <div className="text-left">
                          <h3 className="text-sm font-medium text-gray-900 mb-3">Actions</h3>
                          <div className="space-y-2.5">
                            <button
                              className="flex w-full items-center justify-center rounded-lg border border-blue-300 px-3 py-2 text-sm font-medium text-blue-700 hover:bg-blue-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                              onClick={handleAddNote}
                              disabled={isLoading}
                            >
                              <PencilIcon className="mr-1.5 h-4 w-4" />
                              Add Feedback or Change Request
                            </button>
                            <button
                              className={`flex w-full items-center justify-center rounded-lg border px-3 py-2 text-sm font-medium disabled:cursor-not-allowed disabled:opacity-50 transition-colors
                                  ${
                                    document.approvedByClient
                                      ? 'border-green-500 bg-green-50 text-green-700'
                                      : 'border-green-300 text-green-700 hover:bg-green-50'
                                  }`}
                              onClick={handleApprove}
                              disabled={document.approvedByClient || isLoading}
                            >
                              <CheckCircleIcon className={`mr-1.5 h-4 w-4 ${document.approvedByClient ? 'text-green-500' : 'text-green-400'}`} />
                              {document.approvedByClient ? 'Approved' : 'Approve'}
                            </button>
                            {!document.approvedByClient && (
                              <button
                                className="flex w-full items-center justify-center rounded-lg border border-gray-300 px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                                onClick={handleSendApprovalReminder}
                                disabled={isLoading}
                              >
                                <ClipboardDocumentListIcon className="mr-1.5 h-4 w-4" />
                                Send Approval Reminder
                              </button>
                            )}
                            <button
                              className="flex w-full items-center justify-center rounded-lg border border-gray-300 px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                              onClick={handleArchive}
                              disabled={document.isArchived || isLoading}
                            >
                              <ArchiveBoxIcon className="mr-1.5 h-4 w-4" />
                              {document.isArchived ? 'Archived' : 'Archive'}
                            </button>
                            <button
                              className="flex w-full items-center justify-center rounded-lg border border-red-300 px-3 py-2 text-sm font-medium text-red-700 hover:bg-red-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                              onClick={handleDelete}
                              disabled={isLoading}
                            >
                              <TrashIcon className="mr-1.5 h-4 w-4" />
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DocumentViewerModal;
