import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../../../utils/api';
import { ClockIcon } from '@heroicons/react/24/outline';

const MilestonesTimeline = ({ initialMilestones = [] }) => {
  const category = ['Design Milestones', 'Permissions Milestones', 'Support Milestones', 'Onsite Milestones'];

  const { projectId } = useParams();
  const [milestones, setMilestones] = useState(initialMilestones);
  const [selectedCategory, setSelectedCategory] = useState(category[0]);
  const [filteredMilestones, setFilteredMilestones] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 },
    },
  };

  useEffect(() => {
    // Only fetch if we don't have initial milestones
    if (initialMilestones.length === 0) {
      const fetchMilestones = async () => {
        try {
          const response = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/milestones/project/${projectId}/milestones`);
          setMilestones(response.data);
        } catch (error) {
          setErrorMessage('Failed to fetch milestones');
        }
      };

      fetchMilestones();
    }
  }, [projectId, initialMilestones]);

  useEffect(() => {
    setFilteredMilestones(milestones.filter((milestone) => milestone.category === selectedCategory));
  }, [selectedCategory, milestones]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const countCompletedMilestones = (category) => {
    const milestonesForCategory = milestones.filter((milestone) => milestone.category === category);
    const completedMilestones = milestonesForCategory.filter((milestone) => milestone.status).length;
    return `${completedMilestones} / ${milestonesForCategory.length}`;
  };

  const getTotalMilestones = () => milestones.length;
  const getCompletedMilestones = () => milestones.filter((milestone) => milestone.status).length;
  const getCompletionPercentage = () => {
    const total = getTotalMilestones();
    const completed = getCompletedMilestones();
    return total > 0 ? ((completed / total) * 100).toFixed(0) : 0;
  };

  const hasMilestonesWithDeadline = milestones.some((m) => m.deadline);

  const calculateMilestoneProgress = (milestone) => {
    if (!milestone.subMilestones || milestone.subMilestones.length === 0) {
      return milestone.status ? 100 : 0;
    }
    const completed = milestone.subMilestones.filter((sub) => sub.status).length;
    return Math.round((completed / milestone.subMilestones.length) * 100);
  };

  const calculateProjectProgress = () => {
    if (!milestones.length) return 0;
    const completedMilestones = milestones.filter((m) => m.status).length;
    return Math.round((completedMilestones / milestones.length) * 100);
  };

  const renderProjectProgressBar = () => {
    if (!milestones.length) return null;

    const progress = calculateProjectProgress();

    return (
      <motion.div
        variants={itemVariants}
        className="bg-white/80 backdrop-blur-lg rounded-2xl overflow-hidden
                   border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]"
      >
        <div className="px-6 py-5">
          <div className="flex justify-between items-center mb-2">
            <h3
              className="text-xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] 
                         bg-clip-text text-transparent"
            >
              Project Progress
            </h3>
            <span
              className="text-2xl font-bold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] 
                         bg-clip-text text-transparent"
            >
              {progress}%
            </span>
          </div>

          <div className="relative">
            <div className="overflow-hidden h-4 text-xs flex rounded-full bg-gray-100 p-0.5">
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.8, ease: 'easeOut' }}
                className="shadow-lg flex flex-col text-center whitespace-nowrap text-white justify-center 
                         bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] rounded-full"
              />
            </div>

            {/* Progress markers */}
            <div className="absolute top-full left-0 right-0 flex justify-between mt-2 px-1">
              <div className="flex flex-col items-center">
                <div className="w-1 h-2 bg-gray-300 rounded-full"></div>
                <span className="text-xs text-gray-500 mt-1">0%</span>
              </div>
              <div className="flex flex-col items-center">
                <div className="w-1 h-2 bg-gray-300 rounded-full"></div>
                <span className="text-xs text-gray-500 mt-1">25%</span>
              </div>
              <div className="flex flex-col items-center">
                <div className="w-1 h-2 bg-gray-300 rounded-full"></div>
                <span className="text-xs text-gray-500 mt-1">50%</span>
              </div>
              <div className="flex flex-col items-center">
                <div className="w-1 h-2 bg-gray-300 rounded-full"></div>
                <span className="text-xs text-gray-500 mt-1">75%</span>
              </div>
              <div className="flex flex-col items-center">
                <div className="w-1 h-2 bg-gray-300 rounded-full"></div>
                <span className="text-xs text-gray-500 mt-1">100%</span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 sm:grid-cols-3 gap-4 mt-8">
            <div className="bg-gray-50/50 rounded-xl p-3">
              <div className="text-sm text-gray-500">Total Milestones</div>
              <div className="text-xl font-semibold text-gray-900">{milestones.length}</div>
            </div>
            <div className="bg-gray-50/50 rounded-xl p-3">
              <div className="text-sm text-gray-500">Completed</div>
              <div className="text-xl font-semibold text-gray-900">{milestones.filter((m) => m.status).length}</div>
            </div>
            <div className="bg-gray-50/50 rounded-xl p-3">
              <div className="text-sm text-gray-500">In Progress</div>
              <div className="text-xl font-semibold text-gray-900">{milestones.filter((m) => !m.status).length}</div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  };

  const renderProgressBar = (milestone) => {
    const progress = calculateMilestoneProgress(milestone);
    return (
      <div className="relative pt-1 w-full">
        <div className="flex items-center justify-between">
          <div>
            <span
              className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full 
                           bg-gradient-to-r from-[#2A9D8F]/10 to-[#258B7A]/10 text-[#2A9D8F]"
            >
              Progress
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-[#2A9D8F]">{progress}%</span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mt-2 text-xs flex rounded-full bg-gray-100">
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center 
                     bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] rounded-full"
          />
        </div>
      </div>
    );
  };

  const renderMilestone = (milestone) => (
    <motion.li
      key={milestone._id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="border-b border-gray-100 last:border-b-0"
    >
      <motion.div className="p-6 pl-14 hover:bg-gray-50/50 transition-all duration-300 relative" whileHover={{ backgroundColor: 'rgba(249, 250, 251, 0.8)' }}>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="absolute left-6 top-[1.85rem]">
          <div className={`w-3 h-3 rounded-full ${milestone.status ? 'bg-gradient-to-r from-[#2A9D8F] to-[#258B7A]' : 'bg-gray-200'}`} />
        </motion.div>

        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h4 className="text-base font-medium text-gray-900">{milestone.title}</h4>
            <span
              className={`px-3 py-1 text-sm font-medium rounded-full ${milestone.status ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}
            >
              {milestone.status ? 'Completed' : 'In Progress'}
            </span>
          </div>

          <div className="flex items-center text-sm text-gray-500 space-x-2">
            <ClockIcon className="h-4 w-4" />
            <span>{formatDate(milestone.deadline)}</span>
            <span>•</span>
            <span>
              {milestone.subMilestones?.filter((sub) => sub.status).length || 0}/{milestone.subMilestones?.length || 0} tasks completed
            </span>
          </div>

          {renderProgressBar(milestone)}

          {milestone.subMilestones && milestone.subMilestones.length > 0 && (
            <motion.div initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: 'auto' }} className="mt-4 pl-6 space-y-3">
              {milestone.subMilestones.map((sub) => (
                <motion.div key={sub._id} className="flex items-center justify-between text-sm" whileHover={{ x: 2 }}>
                  <div className="flex items-center space-x-2">
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      className={`w-2 h-2 rounded-full ${sub.status ? 'bg-gradient-to-r from-[#2A9D8F] to-[#258B7A]' : 'bg-gray-200'}`}
                    />
                    <span className="text-gray-600">{sub.title}</span>
                  </div>
                  <span className={`text-xs ${sub.status ? 'text-green-600' : 'text-yellow-600'}`}>{sub.status ? 'Done' : 'Pending'}</span>
                </motion.div>
              ))}
            </motion.div>
          )}
        </div>
      </motion.div>
    </motion.li>
  );

  return (
    <motion.div initial="hidden" animate="visible" variants={containerVariants} className="space-y-10">
      {/* Only render progress bar if there are milestones */}
      {milestones.length > 0 && renderProjectProgressBar()}

      {/* Milestones Summary Card */}
      <motion.div
        variants={itemVariants}
        className="bg-white/80 backdrop-blur-lg rounded-2xl overflow-hidden
                   border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]"
      >
        <div className="px-6 py-5">
          <h3
            className="text-xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] 
                         bg-clip-text text-transparent"
          >
            Milestones Summary
          </h3>
          <p className="mt-2 text-gray-600">Overview of project milestones.</p>
        </div>
        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            <motion.div whileHover={{ backgroundColor: 'rgba(249, 250, 251, 0.5)' }} className="px-6 py-4 transition-all duration-300">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500">Total Milestones</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getTotalMilestones()}</dd>
              </div>
            </motion.div>
            <motion.div whileHover={{ backgroundColor: 'rgba(249, 250, 251, 0.5)' }} className="px-6 py-4 transition-all duration-300">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500">Completed Milestones</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getCompletedMilestones()}</dd>
              </div>
            </motion.div>
            <motion.div whileHover={{ backgroundColor: 'rgba(249, 250, 251, 0.5)' }} className="px-6 py-4 transition-all duration-300">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-500">Completion Status</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getCompletionPercentage()}% Done</dd>
              </div>
            </motion.div>
          </dl>
        </div>
      </motion.div>

      {/* Key Dates Card */}
      <motion.div
        variants={itemVariants}
        className="bg-white/80 backdrop-blur-lg rounded-2xl overflow-hidden
                   border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]"
      >
        <div className="px-6 py-5">
          <h3
            className="text-xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] 
                   bg-clip-text text-transparent"
          >
            Key Dates
          </h3>
          <p className="mt-2 text-gray-600">Important milestone deadlines.</p>
        </div>
        <div className="border-t border-gray-100 px-6 py-6">
          {milestones.length > 0 ? (
            hasMilestonesWithDeadline ? (
              <ul className="space-y-6">
                <AnimatePresence>
                  {milestones
                    .filter((m) => m.deadline)
                    .sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime())
                    .map((milestone, index) => (
                      <motion.li
                        key={milestone._id}
                        initial={{ x: -20, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: 20, opacity: 0 }}
                        transition={{ delay: index * 0.1 }}
                      >
                        <Link to={`/dashboard/project/milestones/${projectId}`}>
                          <motion.div
                            className="flex justify-between items-center bg-white/50 backdrop-blur-sm rounded-xl p-4
                                     hover:bg-white/70 transition-all duration-300 cursor-pointer"
                            whileHover={{
                              y: -2,
                              x: 4,
                              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            }}
                          >
                            <div className="flex-grow">
                              <div className="flex items-center space-x-2">
                                <p className="text-sm font-semibold text-gray-900">{milestone.title}</p>
                                <span
                                  className={`px-2 py-0.5 text-xs font-medium rounded-full
                                             ${milestone.status ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}
                                >
                                  {milestone.status ? 'Completed' : 'Pending'}
                                </span>
                              </div>
                              <div className="mt-1 flex items-center space-x-2">
                                <span className="text-xs text-gray-500 flex items-center">
                                  <ClockIcon className="h-3 w-3 mr-1" />
                                  {formatDate(milestone.deadline)}
                                </span>
                                {milestone.subMilestones && milestone.subMilestones.length > 0 && (
                                  <span className="text-xs text-gray-500">
                                    • {milestone.subMilestones.filter((sub) => sub.status).length}/{milestone.subMilestones.length} tasks completed
                                  </span>
                                )}
                              </div>
                            </div>
                            <motion.div className="ml-4" whileHover={{ scale: 1.05 }}>
                              <div
                                className="h-8 w-8 rounded-full bg-gradient-to-r from-[#2A9D8F]/10 to-[#258B7A]/10 
                                          flex items-center justify-center"
                              >
                                <div
                                  className={`h-3 w-3 rounded-full transition-all duration-300
                                            ${milestone.status ? 'bg-gradient-to-r from-[#2A9D8F] to-[#258B7A]' : 'bg-gray-300'}`}
                                />
                              </div>
                            </motion.div>
                          </motion.div>
                        </Link>
                      </motion.li>
                    ))}
                </AnimatePresence>
              </ul>
            ) : (
              <div className="text-center py-8">
                <div
                  className="inline-flex items-center justify-center w-12 h-12 rounded-full 
                               bg-gray-100 mb-4"
                >
                  <ClockIcon className="h-6 w-6 text-gray-400" />
                </div>
                <p className="text-sm text-gray-500">No milestones with deadlines set.</p>
              </div>
            )
          ) : (
            <div className="text-center py-8">
              <div
                className="inline-flex items-center justify-center w-12 h-12 rounded-full 
                             bg-gray-100 mb-4"
              >
                <ClockIcon className="h-6 w-6 text-gray-400" />
              </div>
              <p className="text-sm text-gray-500">No milestones available.</p>
            </div>
          )}
        </div>
      </motion.div>

      {/* Category Selection */}
      <motion.div
        variants={itemVariants}
        className="bg-white/80 backdrop-blur-lg rounded-2xl overflow-hidden
                   border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]"
      >
        <div className="px-6 py-5">
          <h3
            className="text-xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] 
                         bg-clip-text text-transparent"
          >
            Milestone Categories
          </h3>
          <p className="mt-2 text-gray-600">Select a category to view specific milestones.</p>
        </div>
        <div className="border-t border-gray-100 px-6 py-6">
          <div className="flex flex-wrap gap-3">
            {category.map((cat, index) => (
              <motion.button
                key={index}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedCategory(cat)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300
                           ${
                             cat === selectedCategory
                               ? 'bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] text-white shadow-lg'
                               : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                           }`}
              >
                {cat} ({countCompletedMilestones(cat)})
              </motion.button>
            ))}
          </div>
        </div>
      </motion.div>

      {/* Category Milestones */}
      <motion.div
        variants={itemVariants}
        className="bg-white/80 backdrop-blur-lg rounded-2xl overflow-hidden
                   border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]"
      >
        <div className="px-6 py-5">
          <h3
            className="text-xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] 
                         bg-clip-text text-transparent"
          >
            Milestones for {selectedCategory}
          </h3>
        </div>
        <div className="border-t border-gray-100">
          {filteredMilestones.length > 0 ? (
            <ul className="divide-y divide-gray-100">
              <AnimatePresence>
                {filteredMilestones.map((milestone) => (
                  <Link key={milestone._id} to={`/dashboard/project/milestones/${projectId}`}>
                    {renderMilestone(milestone)}
                  </Link>
                ))}
              </AnimatePresence>
            </ul>
          ) : (
            <p className="px-6 py-6 text-sm text-gray-500">No milestones available for this category.</p>
          )}
        </div>
      </motion.div>

      {errorMessage && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="mt-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-xl relative"
          role="alert"
        >
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {errorMessage}</span>
        </motion.div>
      )}
    </motion.div>
  );
};

export default MilestonesTimeline;
