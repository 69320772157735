import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Check, Clock, Trash2, CheckCircle, Loader2 } from 'lucide-react';

export default function ActionItems({ isEditing, actionItems, onAddItem, onStatusChange, onDescriptionChange, onRemoveItem }) {
  const [loadingStates, setLoadingStates] = useState({
    adding: false,
    items: new Set(),
    removing: new Set(),
  });

  const handleAddItem = async () => {
    setLoadingStates((prev) => ({ ...prev, adding: true }));
    try {
      await onAddItem();
    } finally {
      setLoadingStates((prev) => ({ ...prev, adding: false }));
    }
  };

  const handleStatusChange = async (index, status) => {
    setLoadingStates((prev) => ({
      ...prev,
      items: new Set([...prev.items, index]),
    }));
    try {
      await onStatusChange(index, status);
    } finally {
      setLoadingStates((prev) => {
        const newItems = new Set(prev.items);
        newItems.delete(index);
        return { ...prev, items: newItems };
      });
    }
  };

  const handleRemoveItem = async (index) => {
    setLoadingStates((prev) => ({
      ...prev,
      removing: new Set([...prev.removing, index]),
    }));
    try {
      await onRemoveItem(index);
    } finally {
      setLoadingStates((prev) => {
        const newRemoving = new Set(prev.removing);
        newRemoving.delete(index);
        return { ...prev, removing: newRemoving };
      });
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onAddItem(); // Create a new action item
      // Focus will automatically move to the new item due to AnimatePresence
    }
  };

  return (
    <div className="mt-12 pt-8 border-t border-gray-100">
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl font-semibold text-gray-900">Action Items</h2>
        {isEditing && (
          <button
            onClick={handleAddItem}
            disabled={loadingStates.adding}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-[#2A9D8F] rounded-full 
                     hover:bg-[#238276] transition-colors duration-200 shadow-sm hover:shadow
                     disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loadingStates.adding ? <Loader2 className="w-4 h-4 mr-1.5 animate-spin" /> : <Plus className="w-4 h-4 mr-1.5" />}
            New Task
          </button>
        )}
      </div>

      <div className="space-y-3">
        <AnimatePresence mode="popLayout">
          {(actionItems || []).map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, height: 0 }}
              className="group flex items-center gap-3 py-3 px-4 rounded-xl hover:bg-gray-50 transition-all duration-200"
            >
              {/* Checkbox with loading state */}
              <button
                onClick={() => handleStatusChange(index, item.status)}
                disabled={loadingStates.items.has(index)}
                className="flex-shrink-0 focus:outline-none relative"
              >
                {loadingStates.items.has(index) ? (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="w-5 h-5">
                    <Loader2 className="w-5 h-5 text-[#2A9D8F] animate-spin" />
                  </motion.div>
                ) : (
                  <motion.div
                    className={`w-5 h-5 rounded-full border-2 flex items-center justify-center
                              ${item.status === 'completed' ? 'border-[#2A9D8F] bg-[#2A9D8F]' : 'border-gray-300 hover:border-[#2A9D8F]'}`}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    {item.status === 'completed' && (
                      <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
                        <Check className="w-3 h-3 text-white" />
                      </motion.div>
                    )}
                  </motion.div>
                )}
              </button>

              {/* Content */}
              <div className="flex-grow min-w-0">
                <input
                  type="text"
                  value={item.description}
                  onChange={(e) => onDescriptionChange(index, e.target.value)}
                  disabled={loadingStates.items.has(index)}
                  onKeyPress={(e) => handleKeyPress(e, index)}
                  className={`block w-full bg-transparent border-0 focus:ring-0 p-0
                            ${item.status === 'completed' ? 'text-gray-400 line-through' : 'text-gray-700'} 
                            placeholder:text-gray-400 text-sm transition-colors duration-200
                            disabled:opacity-50`}
                  placeholder="What needs to be done?"
                />

                {item.assignedTo && (
                  <div className="flex items-center mt-1 text-xs text-gray-400">
                    <Clock className="w-3 h-3 mr-1" />
                    {new Date(item.createdAt).toLocaleDateString()}
                    <span className="mx-2">•</span>
                    {item.assignedTo}
                  </div>
                )}
              </div>

              {/* Delete Button with loading state */}
              <motion.button
                onClick={() => handleRemoveItem(index)}
                disabled={loadingStates.removing.has(index)}
                className={`flex-shrink-0 text-gray-400 hover:text-red-500 transition-all duration-200
                         ${loadingStates.removing.has(index) ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'}`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {loadingStates.removing.has(index) ? <Loader2 className="w-4 h-4 animate-spin text-gray-400" /> : <Trash2 className="w-4 h-4" />}
              </motion.button>
            </motion.div>
          ))}
        </AnimatePresence>

        {(!actionItems || actionItems.length === 0) && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="text-center py-8">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
              <CheckCircle className="w-6 h-6 text-gray-400" />
            </div>
            <p className="text-sm text-gray-500">No tasks yet. Click "New Task" to create one.</p>
          </motion.div>
        )}
      </div>
    </div>
  );
}
