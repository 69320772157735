import React, { useState } from 'react';
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { imageService } from '../../../services/imageService';
import { toast } from 'react-hot-toast';

const Modal = ({ currentImage, handleCloseModal }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      await imageService.downloadImage(currentImage._id, currentImage.filename);
      toast.success('Image downloaded successfully');
    } catch (error) {
      console.error('Failed to download image:', error);
      toast.error('Failed to download image');
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 bg-black/80 backdrop-blur-sm flex items-center justify-center" onClick={handleCloseModal}>
      <div className="relative max-w-7xl w-full mx-4" onClick={(e) => e.stopPropagation()}>
        <div className="absolute top-4 right-4 flex gap-2 z-10">
          <button onClick={handleDownload} disabled={isDownloading} className="p-3 bg-white/90 hover:bg-white rounded-full transition-all shadow-lg group">
            <ArrowDownTrayIcon className={`w-5 h-5 ${isDownloading ? 'animate-bounce' : 'group-hover:scale-110 transition-transform'}`} />
          </button>
          <button onClick={handleCloseModal} className="p-3 bg-white/90 hover:bg-white rounded-full transition-all shadow-lg group">
            <XMarkIcon className="w-5 h-5 group-hover:scale-110 transition-transform" />
          </button>
        </div>

        <div className="bg-white/5 backdrop-blur-sm rounded-2xl p-2 shadow-2xl">
          <img src={currentImage.signedUrl} alt={currentImage.filename} className="max-h-[85vh] mx-auto object-contain rounded-xl" />
          <p className="text-center mt-4 text-white/90 font-medium">{currentImage.filename}</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
