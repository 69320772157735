import React, { useState, useEffect } from 'react';
import {
  ArrowRight,
  Check,
  FileText,
  Database,
  History,
  Users,
  Tag,
  Shield,
  ListTodo,
  AlertTriangle,
  Clock,
  Bell,
  Link,
  MessageSquare,
  BarChart2,
  Share2,
  Image,
  Calendar,
  Star,
  HelpCircle,
  Zap,
  Award,
  Compass,
  ArrowUpRight,
  Layout,
  ArrowDown,
} from 'lucide-react';

const DesignVaultPremium = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('hero');
  const [isVisible, setIsVisible] = useState({
    features: false,
    documents: false,
    issues: false,
    collab: false,
    milestones: false,
    testimonials: false,
  });

  useEffect(() => {
    const handleScroll = () => {
      // Handle header transparency/opacity
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      // Handle section visibility for animations
      const sections = ['features', 'documents', 'issues', 'collab', 'milestones', 'testimonials'];
      sections.forEach((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          const isInViewport = rect.top < window.innerHeight * 0.75;

          setIsVisible((prev) => ({
            ...prev,
            [section]: isInViewport,
          }));

          // Set current active section for nav highlighting
          if (rect.top < window.innerHeight * 0.5 && rect.bottom > 0) {
            setActiveSection(section);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    // Trigger once on mount to check initial visibility
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 80,
        behavior: 'smooth',
      });
      setIsMenuOpen(false);
    }
  };

  return (
    <div className="min-h-screen font-sans text-gray-900 overflow-hidden bg-gradient-to-br from-gray-50 to-blue-50">
      {/* Header/Navigation */}
      <header className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'py-3 bg-white/95 backdrop-blur-md shadow-lg' : 'py-5 bg-transparent'}`}>
        <div className="container mx-auto px-6 flex justify-between items-center">
          <div className="flex items-center">
            <div className="w-10 h-10 relative overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-tr from-blue-600 to-indigo-600 rounded-lg transform rotate-3"></div>
              <div className="absolute inset-0 flex items-center justify-center text-white font-bold text-xl">D</div>
            </div>
            <span className="ml-3 text-2xl font-extrabold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">DesignVault</span>
          </div>

          <div className="hidden md:flex items-center space-x-1">
            <nav className="flex items-center">
              {['features', 'documents', 'issues', 'collab', 'milestones', 'testimonials'].map((section) => (
                <button
                  key={section}
                  onClick={() => scrollToSection(section)}
                  className={`px-4 py-2 mx-1 rounded-full font-medium transition-all ${
                    activeSection === section ? 'text-white bg-gradient-to-r from-blue-600 to-indigo-600 shadow-md' : 'text-gray-700 hover:text-blue-600'
                  }`}
                >
                  {section.charAt(0).toUpperCase() + section.slice(1)}
                </button>
              ))}
            </nav>
            <div className="flex items-center ml-6 space-x-3">
              <button className="px-5 py-2 rounded-full border-2 border-blue-600 text-blue-600 font-medium hover:bg-blue-50 transition-colors">Log In</button>
              <button className="px-5 py-2 rounded-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium shadow-lg hover:shadow-xl hover:-translate-y-0.5 transition-all">
                Get Started
              </button>
            </div>
          </div>

          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden bg-white border-t border-gray-100 absolute w-full shadow-xl rounded-b-2xl overflow-hidden">
            <div className="container mx-auto px-6 py-4">
              <nav className="flex flex-col space-y-2">
                {['features', 'documents', 'issues', 'collab', 'milestones', 'testimonials'].map((section) => (
                  <button
                    key={section}
                    onClick={() => scrollToSection(section)}
                    className={`px-4 py-3 rounded-lg font-medium text-left transition-colors ${
                      activeSection === section ? 'bg-blue-50 text-blue-600' : 'text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                  </button>
                ))}
                <div className="flex flex-col space-y-3 pt-4 border-t border-gray-100 mt-2">
                  <button className="px-4 py-3 rounded-lg border-2 border-blue-600 text-blue-600 font-medium hover:bg-blue-50 transition-colors">Log In</button>
                  <button className="px-4 py-3 rounded-lg bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium">Get Started</button>
                </div>
              </nav>
            </div>
          </div>
        )}
      </header>

      {/* Hero Section */}
      <section id="hero" className="pt-32 pb-20 md:py-40 px-6 relative overflow-hidden">
        {/* Background Graphics */}
        <div className="absolute -top-40 -right-40 w-96 h-96 bg-gradient-to-br from-blue-400/20 to-indigo-400/20 rounded-full blur-3xl"></div>
        <div className="absolute top-1/2 -left-40 w-80 h-80 bg-gradient-to-tr from-purple-400/10 to-pink-400/10 rounded-full blur-3xl"></div>
        <div className="absolute -bottom-20 right-1/4 w-64 h-64 bg-gradient-to-r from-yellow-400/10 to-orange-400/10 rounded-full blur-3xl"></div>

        <div className="container mx-auto relative z-10">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-12 md:mb-0">
              <div className="inline-flex items-center bg-blue-50 text-blue-600 rounded-full px-3 py-1 text-sm font-medium mb-6">
                <Star className="w-4 h-4 mr-1" />
                <span>The Future of Architectural Project Management</span>
              </div>
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-6">
                Streamline Your{' '}
                <span className="bg-gradient-to-r from-blue-600 via-indigo-600 to-purple-600 bg-clip-text text-transparent">Architectural Workflow</span>
              </h1>
              <p className="text-lg md:text-xl text-gray-600 mb-8 max-w-lg">
                DesignVault brings together powerful document management and issue tracking in one unified platform designed specifically for architectural
                teams.
              </p>
              <div className="flex flex-wrap gap-4">
                <button className="px-8 py-4 rounded-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium shadow-lg hover:shadow-xl hover:-translate-y-0.5 transition-all flex items-center">
                  Get Started Free
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                <button className="px-8 py-4 rounded-full border-2 border-blue-600 text-blue-600 font-medium hover:bg-blue-50 transition-colors flex items-center">
                  Watch Demo
                  <div className="ml-2 w-5 h-5 rounded-full bg-blue-600 flex items-center justify-center text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              </div>
              <div className="mt-8 flex items-center">
                <div className="flex -space-x-2">
                  {[1, 2, 3, 4].map((i) => (
                    <div
                      key={i}
                      className="w-10 h-10 rounded-full border-2 border-white bg-gradient-to-r from-blue-500 to-indigo-500 flex items-center justify-center text-white text-xs font-bold"
                    >
                      {['JD', 'AR', 'MT', '+'][i - 1]}
                    </div>
                  ))}
                </div>
                <p className="ml-4 text-gray-600">
                  Trusted by <span className="font-semibold">2,000+</span> architectural firms
                </p>
              </div>
            </div>
            <div className="md:w-1/2 flex justify-center">
              <div className="relative">
                <div className="absolute -top-10 -left-10 w-64 h-64 bg-blue-200 rounded-full mix-blend-multiply filter blur-3xl opacity-30"></div>
                <div className="absolute -bottom-10 -right-10 w-64 h-64 bg-indigo-200 rounded-full mix-blend-multiply filter blur-3xl opacity-30"></div>

                <div className="relative rounded-2xl overflow-hidden shadow-2xl border border-gray-200 bg-white">
                  <div className="h-8 bg-gray-100 flex items-center px-4 space-x-1">
                    <div className="w-3 h-3 rounded-full bg-red-400"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-3 h-3 rounded-full bg-green-400"></div>
                  </div>
                  <img src="/api/placeholder/600/400" alt="DesignVault Dashboard" className="w-full" />
                </div>

                <div className="absolute -left-12 top-1/3 bg-white rounded-xl shadow-lg p-4 w-60 transform -rotate-6 border border-gray-100">
                  <div className="flex items-center mb-3">
                    <div className="w-10 h-10 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600">
                      <FileText className="w-5 h-5" />
                    </div>
                    <div className="ml-3">
                      <div className="font-semibold text-sm">Project Documents</div>
                      <div className="text-xs text-gray-500">Last updated 2 hours ago</div>
                    </div>
                  </div>
                  <div className="h-1 w-full bg-gray-100 rounded-full">
                    <div className="h-1 w-3/4 bg-indigo-500 rounded-full"></div>
                  </div>
                </div>

                <div className="absolute -right-10 bottom-10 bg-white rounded-xl shadow-lg p-4 w-52 transform rotate-3 border border-gray-100">
                  <div className="flex justify-between items-center mb-3">
                    <div className="font-semibold text-sm">Project Timeline</div>
                    <div className="text-xs px-2 py-1 bg-green-100 text-green-700 rounded-full">On Track</div>
                  </div>
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-green-500 mr-2"></div>
                      <div className="text-xs">Planning Phase</div>
                    </div>
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-green-500 mr-2"></div>
                      <div className="text-xs">Design Phase</div>
                    </div>
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-blue-500 mr-2"></div>
                      <div className="text-xs font-medium">Development Phase</div>
                    </div>
                    <div className="flex items-center">
                      <div className="w-4 h-4 rounded-full bg-gray-200 mr-2"></div>
                      <div className="text-xs text-gray-400">Review Phase</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-24 text-center">
            <button
              onClick={() => scrollToSection('features')}
              className="inline-flex flex-col items-center text-gray-500 hover:text-blue-600 transition-colors"
            >
              <span className="text-sm font-medium mb-2">Discover Features</span>
              <ArrowDown className="h-5 w-5 animate-bounce" />
            </button>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section
        id="features"
        className={`py-24 px-6 transition-all duration-1000 transform ${isVisible.features ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}
      >
        <div className="container mx-auto">
          <div className="text-center mb-16">
            <div className="inline-flex items-center bg-blue-50 text-blue-600 rounded-full px-3 py-1 text-sm font-medium mb-4">
              <Compass className="w-4 h-4 mr-1" />
              <span>Features Overview</span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">All-in-One Solution for Architectural Teams</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              DesignVault brings together everything architectural teams need to manage documents, track issues, and collaborate effectively.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<FileText className="h-6 w-6" />}
              title="Multi-Format Support"
              description="Upload and manage PDFs, CAD files, 3D models, Google Docs, images, and more in one central location."
              color="blue"
            />
            <FeatureCard
              icon={<Database className="h-6 w-6" />}
              title="Cloud-Based Storage"
              description="Secure access to documents anytime, anywhere with reliable cloud storage and automatic backups."
              color="indigo"
            />
            <FeatureCard
              icon={<History className="h-6 w-6" />}
              title="Version Control"
              description="Track revisions and maintain a complete history of document updates with easy rollback options."
              color="violet"
            />
            <FeatureCard
              icon={<Tag className="h-6 w-6" />}
              title="Automated Tagging"
              description="Intelligent categorization of documents for easy searching and retrieval when you need them."
              color="purple"
            />
            <FeatureCard
              icon={<ListTodo className="h-6 w-6" />}
              title="Task Management"
              description="Assign tasks to team members with deadlines and track progress throughout your project lifecycle."
              color="pink"
            />
            <FeatureCard
              icon={<Calendar className="h-6 w-6" />}
              title="Milestone Manager"
              description="Set project milestones, track completion, and ensure your projects stay on schedule."
              color="rose"
            />
          </div>
        </div>
      </section>

      {/* Documents Manager Section */}
      <section
        id="documents"
        className={`py-24 px-6 bg-gradient-to-br from-blue-50 to-indigo-50 relative transition-all duration-1000 transform ${
          isVisible.documents ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}
      >
        {/* Background graphics */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
          <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-blue-300/10 to-indigo-300/10 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-1/3 h-1/3 bg-gradient-to-tr from-indigo-300/10 to-blue-300/10 rounded-full blur-3xl"></div>
        </div>

        <div className="container mx-auto relative">
          <div className="text-center mb-16">
            <div className="inline-flex items-center bg-indigo-50 text-indigo-600 rounded-full px-3 py-1 text-sm font-medium mb-4">
              <FileText className="w-4 h-4 mr-1" />
              <span>Documents Manager</span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Organize, Store, and Collaborate with Ease</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Managing architectural design files can be complex. Our Documents Manager simplifies file organization, enabling seamless collaboration for your
              team.
            </p>
          </div>

          <div className="flex flex-col md:flex-row items-center bg-white rounded-3xl overflow-hidden shadow-xl border border-gray-100">
            <div className="md:w-1/2 p-6 md:p-12">
              <div className="space-y-6">
                <FeatureItem
                  icon={<FileText className="h-5 w-5" />}
                  title="Multi-Format Support"
                  description="Upload and manage PDFs, CAD files, 3D models, Google Docs, images, and more."
                />
                <FeatureItem
                  icon={<Database className="h-5 w-5" />}
                  title="Cloud-Based Storage"
                  description="Secure access to documents anytime, anywhere with reliable cloud storage."
                />
                <FeatureItem
                  icon={<History className="h-5 w-5" />}
                  title="Version Control"
                  description="Track revisions and maintain a complete history of document updates."
                />
                <FeatureItem
                  icon={<Users className="h-5 w-5" />}
                  title="Collaboration Tools"
                  description="Share files, leave comments, and ensure team alignment on all documents."
                />
                <FeatureItem
                  icon={<Tag className="h-5 w-5" />}
                  title="Automated Tagging & Organization"
                  description="AI-powered categorization of documents for easy searching and retrieval."
                />
                <FeatureItem
                  icon={<Shield className="h-5 w-5" />}
                  title="Role-Based Permissions"
                  description="Control access levels for different team members and external stakeholders."
                />
                <FeatureItem
                  icon={<Image className="h-5 w-5" />}
                  title="Built-in Previews"
                  description="View images, 3D models, and documents without downloading or opening other apps."
                />

                <div className="pt-4">
                  <button className="px-6 py-3 rounded-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium shadow-lg hover:shadow-xl hover:-translate-y-0.5 transition-all flex items-center">
                    Explore Documents Manager
                    <ArrowUpRight className="ml-2 h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-blue-50 p-6 md:p-0">
              <div className="relative h-full min-h-[400px] md:min-h-[600px] flex items-center justify-center overflow-hidden">
                <div className="relative mx-auto w-full max-w-md transform transition-all hover:scale-105 duration-500">
                  <div className="bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100">
                    <div className="h-10 bg-gray-50 border-b border-gray-100 flex items-center px-4">
                      <div className="flex space-x-1 mr-4">
                        <div className="w-2.5 h-2.5 rounded-full bg-red-400"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-yellow-400"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-green-400"></div>
                      </div>
                      <div className="text-xs font-medium text-gray-600">Project Documents</div>
                    </div>
                    <div className="p-4">
                      <div className="flex justify-between items-center mb-4">
                        <div className="text-sm font-semibold">Tower Design Project</div>
                        <div className="flex items-center text-xs text-gray-500">
                          <Clock className="w-3 h-3 mr-1" />
                          Updated 2 days ago
                        </div>
                      </div>

                      <div className="space-y-3">
                        <div className="bg-blue-50 p-3 rounded-lg border border-blue-100 flex items-start">
                          <div className="p-2 bg-blue-500 text-white rounded mr-3">
                            <FileText className="w-4 h-4" />
                          </div>
                          <div>
                            <div className="text-sm font-medium">Tower_Facade_v2.3.pdf</div>
                            <div className="text-xs text-gray-500 mt-1">PDF Document • 4.2MB</div>
                            <div className="flex items-center mt-2 space-x-2">
                              <div className="flex items-center text-xs text-gray-500">
                                <History className="w-3 h-3 mr-1" />6 versions
                              </div>
                              <div className="flex items-center text-xs text-gray-500">
                                <MessageSquare className="w-3 h-3 mr-1" />8 comments
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bg-gray-50 p-3 rounded-lg border border-gray-100 flex items-start">
                          <div className="p-2 bg-indigo-500 text-white rounded mr-3">
                            <Image className="w-4 h-4" />
                          </div>
                          <div>
                            <div className="text-sm font-medium">Lobby_Rendering_Final.jpg</div>
                            <div className="text-xs text-gray-500 mt-1">Image • 8.7MB</div>
                            <div className="flex items-center mt-2 space-x-2">
                              <div className="flex items-center text-xs text-gray-500">
                                <History className="w-3 h-3 mr-1" />3 versions
                              </div>
                              <div className="flex items-center text-xs text-gray-500">
                                <MessageSquare className="w-3 h-3 mr-1" />
                                12 comments
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bg-gray-50 p-3 rounded-lg border border-gray-100 flex items-start">
                          <div className="p-2 bg-purple-500 text-white rounded mr-3">
                            <Layout className="w-4 h-4" />
                          </div>
                          <div>
                            <div className="text-sm font-medium">Floor_Plans_Basement.dwg</div>
                            <div className="text-xs text-gray-500 mt-1">CAD Drawing • 12.1MB</div>
                            <div className="flex items-center mt-2 space-x-2">
                              <div className="flex items-center text-xs text-gray-500">
                                <History className="w-3 h-3 mr-1" />8 versions
                              </div>
                              <div className="flex items-center text-xs text-gray-500">
                                <MessageSquare className="w-3 h-3 mr-1" />5 comments
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -right-16 top-24 bg-white rounded-lg shadow-lg p-3 transform rotate-6 border border-gray-100">
                    <div className="flex items-center space-x-2">
                      <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center text-green-600">
                        <Check className="w-4 h-4" />
                      </div>
                      <div>
                        <div className="text-xs font-medium">Floor Plans Approved</div>
                        <div className="text-xs text-gray-500">by John Davis</div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -left-12 -bottom-6 bg-white rounded-lg shadow-lg p-3 transform -rotate-3 border border-gray-100">
                    <div className="flex items-center space-x-2">
                      <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white">
                        <Users className="w-4 h-4" />
                      </div>
                      <div>
                        <div className="text-xs font-medium">Shared with Team</div>
                        <div className="text-xs text-gray-500">7 members</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Issue Tracker Section */}
      <section
        id="issues"
        className={`py-24 px-6 transition-all duration-1000 transform ${isVisible.issues ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}
      >
        <div className="container mx-auto">
          <div className="text-center mb-16">
            <div className="inline-flex items-center bg-purple-50 text-purple-600 rounded-full px-3 py-1 text-sm font-medium mb-4">
              <AlertTriangle className="w-4 h-4 mr-1" />
              <span>Issue Tracker</span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Keep Your Projects on Track</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Architectural design projects require meticulous issue tracking to ensure quality and compliance. Our Issue Tracker provides a structured system
              for managing challenges.
            </p>
          </div>

          <div className="flex flex-col-reverse md:flex-row items-center bg-white rounded-3xl overflow-hidden shadow-xl border border-gray-100">
            <div className="md:w-1/2 p-6 md:p-12">
              <div className="space-y-6">
                <FeatureItem
                  icon={<ListTodo className="h-5 w-5" />}
                  title="Issue Logging & Categorization"
                  description="Document project issues with detailed descriptions, attachments, and due dates."
                />
                <FeatureItem
                  icon={<AlertTriangle className="h-5 w-5" />}
                  title="Priority Assignment"
                  description="Rank issues by severity (critical, high, medium, low) to focus on what matters most."
                />
                <FeatureItem
                  icon={<Clock className="h-5 w-5" />}
                  title="Action List & Task Management"
                  description="Assign tasks to team members with deadlines and progress tracking systems."
                />
                <FeatureItem
                  icon={<Bell className="h-5 w-5" />}
                  title="Automated Notifications"
                  description="Keep stakeholders informed about issue updates and resolutions with smart alerts."
                />
                <FeatureItem
                  icon={<Link className="h-5 w-5" />}
                  title="Integrated with Documents Manager"
                  description="Link issues to relevant project files for complete context and faster resolution."
                />
                <FeatureItem
                  icon={<MessageSquare className="h-5 w-5" />}
                  title="Real-Time Collaboration"
                  description="Teams can comment, update, and resolve issues collaboratively from anywhere."
                />
                <FeatureItem
                  icon={<Calendar className="h-5 w-5" />}
                  title="Milestone Tracking"
                  description="Connect issues to project milestones to keep your timeline on track."
                />

                <div className="pt-4">
                  <button className="px-6 py-3 rounded-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium shadow-lg hover:shadow-xl hover:-translate-y-0.5 transition-all flex items-center">
                    Explore Issue Tracker
                    <ArrowUpRight className="ml-2 h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-purple-50 p-6 md:p-0">
              <div className="relative h-full min-h-[400px] md:min-h-[600px] flex items-center justify-center overflow-hidden">
                <div className="relative mx-auto w-full max-w-md transform transition-all hover:scale-105 duration-500">
                  <div className="bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100">
                    <div className="h-10 bg-gray-50 border-b border-gray-100 flex items-center px-4">
                      <div className="flex space-x-1 mr-4">
                        <div className="w-2.5 h-2.5 rounded-full bg-red-400"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-yellow-400"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-green-400"></div>
                      </div>
                      <div className="text-xs font-medium text-gray-600">Project Issues</div>
                    </div>
                    <div className="p-4">
                      <div className="flex justify-between items-center mb-4">
                        <div className="text-sm font-semibold">Tower Design Project Issues</div>
                        <div className="flex items-center">
                          <button className="p-1.5 bg-gray-50 rounded-md text-gray-600 text-xs">Filter</button>
                        </div>
                      </div>

                      <div className="space-y-3">
                        <div className="bg-red-50 p-3 rounded-lg border border-red-100 flex items-start">
                          <div className="p-2 bg-red-500 text-white rounded mr-3">
                            <AlertTriangle className="w-4 h-4" />
                          </div>
                          <div className="flex-1">
                            <div className="text-sm font-medium">Façade Material Compliance</div>
                            <div className="text-xs text-gray-500 mt-1">Critical Priority • Created 3 days ago</div>
                            <div className="flex items-center mt-2 space-x-2">
                              <div className="px-2 py-1 bg-red-100 text-red-700 rounded-full text-xs">Critical</div>
                              <div className="px-2 py-1 bg-yellow-100 text-yellow-700 rounded-full text-xs">In Progress</div>
                            </div>
                          </div>
                        </div>

                        <div className="bg-yellow-50 p-3 rounded-lg border border-yellow-100 flex items-start">
                          <div className="p-2 bg-yellow-500 text-white rounded mr-3">
                            <AlertTriangle className="w-4 h-4" />
                          </div>
                          <div className="flex-1">
                            <div className="text-sm font-medium">Electrical Layout Revision</div>
                            <div className="text-xs text-gray-500 mt-1">High Priority • Created 4 days ago</div>
                            <div className="flex items-center mt-2 space-x-2">
                              <div className="px-2 py-1 bg-yellow-100 text-yellow-700 rounded-full text-xs">High</div>
                              <div className="px-2 py-1 bg-green-100 text-green-700 rounded-full text-xs">Assigned</div>
                            </div>
                          </div>
                        </div>

                        <div className="bg-blue-50 p-3 rounded-lg border border-blue-100 flex items-start">
                          <div className="p-2 bg-blue-500 text-white rounded mr-3">
                            <HelpCircle className="w-4 h-4" />
                          </div>
                          <div className="flex-1">
                            <div className="text-sm font-medium">Lobby Ceiling Height Adjustment</div>
                            <div className="text-xs text-gray-500 mt-1">Medium Priority • Created 5 days ago</div>
                            <div className="flex items-center mt-2 space-x-2">
                              <div className="px-2 py-1 bg-blue-100 text-blue-700 rounded-full text-xs">Medium</div>
                              <div className="px-2 py-1 bg-green-100 text-green-700 rounded-full text-xs">In Review</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -right-16 top-24 bg-white rounded-lg shadow-lg p-3 transform rotate-6 border border-gray-100">
                    <div className="flex items-center space-x-2">
                      <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600">
                        <MessageSquare className="w-4 h-4" />
                      </div>
                      <div>
                        <div className="text-xs font-medium">New Comment</div>
                        <div className="text-xs text-gray-500">on Façade Material</div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -left-12 -bottom-6 bg-white rounded-lg shadow-lg p-3 transform -rotate-3 border border-gray-100">
                    <div className="flex items-center space-x-2">
                      <div className="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center text-white">
                        <Check className="w-4 h-4" />
                      </div>
                      <div>
                        <div className="text-xs font-medium">Issue Resolved</div>
                        <div className="text-xs text-gray-500">Electrical Layout</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Collaboration Section */}
      <section
        id="collab"
        className={`py-24 px-6 bg-gradient-to-br from-green-50 to-blue-50 relative transition-all duration-1000 transform ${
          isVisible.collab ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}
      >
        {/* Background graphics */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
          <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-gradient-to-br from-green-300/10 to-blue-300/10 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-gradient-to-tr from-blue-300/10 to-green-300/10 rounded-full blur-3xl"></div>
        </div>

        <div className="container mx-auto relative">
          <div className="text-center mb-16">
            <div className="inline-flex items-center bg-green-50 text-green-600 rounded-full px-3 py-1 text-sm font-medium mb-4">
              <Users className="w-4 h-4 mr-1" />
              <span>Team Collaboration</span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Work Together Seamlessly</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Architectural projects require close coordination between multiple stakeholders. Our collaboration tools break down silos and enable efficient
              team communication.
            </p>
          </div>

          <div className="flex flex-col md:flex-row items-center bg-white rounded-3xl overflow-hidden shadow-xl border border-gray-100">
            <div className="md:w-1/2 bg-green-50 p-6 md:p-0 order-2 md:order-1">
              <div className="relative h-full min-h-[400px] md:min-h-[600px] flex items-center justify-center overflow-hidden">
                <div className="relative mx-auto w-full max-w-md transform transition-all hover:scale-105 duration-500">
                  <div className="bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100">
                    <div className="h-10 bg-gray-50 border-b border-gray-100 flex items-center px-4">
                      <div className="flex space-x-1 mr-4">
                        <div className="w-2.5 h-2.5 rounded-full bg-red-400"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-yellow-400"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-green-400"></div>
                      </div>
                      <div className="text-xs font-medium text-gray-600">Team Collaboration</div>
                    </div>
                    <div className="p-4">
                      <div className="flex justify-between items-center mb-4">
                        <div className="text-sm font-semibold">Tower Design Discussion</div>
                        <div className="flex items-center text-xs text-gray-500">
                          <Clock className="w-3 h-3 mr-1" />
                          Active now
                        </div>
                      </div>

                      <div className="space-y-3">
                        <div className="flex items-start">
                          <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white text-xs font-bold mr-3">JD</div>
                          <div className="bg-gray-100 rounded-lg p-3 max-w-xs">
                            <div className="text-sm">I've updated the facade materials to address the compliance issues we found last week.</div>
                            <div className="text-xs text-gray-500 mt-1">John Davis • 10:30 AM</div>
                          </div>
                        </div>

                        <div className="flex items-start">
                          <div className="w-8 h-8 rounded-full bg-purple-500 flex items-center justify-center text-white text-xs font-bold mr-3">AR</div>
                          <div className="bg-gray-100 rounded-lg p-3 max-w-xs">
                            <div className="text-sm">Great work! I've reviewed the changes and they look good. We should update the milestone status.</div>
                            <div className="text-xs text-gray-500 mt-1">Anna Rodriguez • 11:15 AM</div>
                          </div>
                        </div>

                        <div className="flex items-start justify-end">
                          <div className="bg-green-100 rounded-lg p-3 max-w-xs">
                            <div className="text-sm">I'll update the milestone. Also, can we discuss the lobby ceiling height this afternoon?</div>
                            <div className="text-xs text-gray-500 mt-1">You • Just now</div>
                          </div>
                          <div className="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center text-white text-xs font-bold ml-3">ME</div>
                        </div>

                        <div className="bg-blue-50 p-2 rounded-lg border border-blue-100 flex items-center">
                          <div className="p-2 bg-blue-500 text-white rounded mr-3">
                            <FileText className="w-4 h-4" />
                          </div>
                          <div>
                            <div className="text-xs font-medium">John shared a file</div>
                            <div className="text-xs text-blue-600">Tower_Facade_v2.3.pdf</div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 flex items-center border-t border-gray-100 pt-4">
                        <input
                          type="text"
                          placeholder="Type your message..."
                          className="flex-1 text-sm p-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500"
                        />
                        <button className="ml-2 p-2 bg-green-500 text-white rounded-lg">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -right-16 top-24 bg-white rounded-lg shadow-lg p-3 transform rotate-6 border border-gray-100">
                    <div className="flex items-center space-x-2">
                      <div className="w-8 h-8 rounded-full bg-orange-100 flex items-center justify-center text-orange-600">
                        <Bell className="w-4 h-4" />
                      </div>
                      <div>
                        <div className="text-xs font-medium">Team Meeting</div>
                        <div className="text-xs text-gray-500">In 15 minutes</div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -left-12 -bottom-6 bg-white rounded-lg shadow-lg p-3 transform -rotate-3 border border-gray-100">
                    <div className="flex items-center space-x-2">
                      <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600">
                        <Share2 className="w-4 h-4" />
                      </div>
                      <div>
                        <div className="text-xs font-medium">Share Project</div>
                        <div className="text-xs text-gray-500">With Client</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="md:w-1/2 p-6 md:p-12 order-1 md:order-2">
              <div className="space-y-6">
                <FeatureItem
                  icon={<MessageSquare className="h-5 w-5" />}
                  title="Real-Time Chat & Messaging"
                  description="Instant communication with team members directly within the context of your projects."
                />
                <FeatureItem
                  icon={<Users className="h-5 w-5" />}
                  title="Team Management"
                  description="Create and organize teams with specific roles and permissions for each project."
                />
                <FeatureItem
                  icon={<Share2 className="h-5 w-5" />}
                  title="External Stakeholder Sharing"
                  description="Share specific documents and progress with clients and contractors with controlled access."
                />
                <FeatureItem
                  icon={<Bell className="h-5 w-5" />}
                  title="Smart Notifications"
                  description="Stay informed with customizable alerts about important project updates and mentions."
                />
                <FeatureItem
                  icon={<BarChart2 className="h-5 w-5" />}
                  title="Activity Tracking"
                  description="Monitor team engagement and project progress with detailed activity logs."
                />
                <FeatureItem
                  icon={<Calendar className="h-5 w-5" />}
                  title="Integrated Calendar"
                  description="Schedule and manage team meetings, deadlines, and project milestones in one place."
                />
                <FeatureItem
                  icon={<Link className="h-5 w-5" />}
                  title="Contextual Discussions"
                  description="Comment directly on documents and issues to keep conversations organized and relevant."
                />

                <div className="pt-4">
                  <button className="px-6 py-3 rounded-full bg-gradient-to-r from-green-600 to-blue-600 text-white font-medium shadow-lg hover:shadow-xl hover:-translate-y-0.5 transition-all flex items-center">
                    Explore Collaboration Tools
                    <ArrowUpRight className="ml-2 h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Milestones Section */}
      <section
        id="milestones"
        className={`py-24 px-6 transition-all duration-1000 transform ${isVisible.milestones ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}
      >
        <div className="container mx-auto">
          <div className="text-center mb-16">
            <div className="inline-flex items-center bg-orange-50 text-orange-600 rounded-full px-3 py-1 text-sm font-medium mb-4">
              <Calendar className="w-4 h-4 mr-1" />
              <span>Project Milestones</span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Keep Your Projects On Schedule</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Track progress, set deadlines, and ensure your architectural projects stay on time and within budget with our comprehensive milestone management.
            </p>
          </div>

          <div className="flex flex-col md:flex-row items-center bg-white rounded-3xl overflow-hidden shadow-xl border border-gray-100">
            <div className="md:w-1/2 p-6 md:p-12">
              <div className="space-y-6">
                <FeatureItem
                  icon={<Calendar className="h-5 w-5" />}
                  title="Visual Timeline"
                  description="View your project schedule at a glance with interactive Gantt charts and calendars."
                />
                <FeatureItem
                  icon={<Clock className="h-5 w-5" />}
                  title="Deadline Management"
                  description="Set and track important deadlines with automated reminders and notifications."
                />
                <FeatureItem
                  icon={<ListTodo className="h-5 w-5" />}
                  title="Task Dependencies"
                  description="Create relationships between tasks to ensure proper sequencing of project activities."
                />
                <FeatureItem
                  icon={<BarChart2 className="h-5 w-5" />}
                  title="Progress Tracking"
                  description="Monitor completion status and identify potential delays before they impact your timeline."
                />
                <FeatureItem
                  icon={<Users className="h-5 w-5" />}
                  title="Resource Allocation"
                  description="Assign team members to specific tasks and track individual workloads for optimal staffing."
                />
                <FeatureItem
                  icon={<Bell className="h-5 w-5" />}
                  title="Milestone Alerts"
                  description="Receive notifications about upcoming and overdue milestones to keep projects on track."
                />
                <FeatureItem
                  icon={<Zap className="h-5 w-5" />}
                  title="Templates & Automation"
                  description="Create reusable milestone templates for similar projects and automate regular updates."
                />

                <div className="pt-4">
                  <button className="px-6 py-3 rounded-full bg-gradient-to-r from-orange-500 to-red-500 text-white font-medium shadow-lg hover:shadow-xl hover:-translate-y-0.5 transition-all flex items-center">
                    Explore Milestone Management
                    <ArrowUpRight className="ml-2 h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-orange-50 p-6 md:p-0">
              <div className="relative h-full min-h-[400px] md:min-h-[600px] flex items-center justify-center overflow-hidden">
                <div className="relative mx-auto w-full max-w-md transform transition-all hover:scale-105 duration-500">
                  <div className="bg-white rounded-xl shadow-xl overflow-hidden border border-gray-100">
                    <div className="h-10 bg-gray-50 border-b border-gray-100 flex items-center px-4">
                      <div className="flex space-x-1 mr-4">
                        <div className="w-2.5 h-2.5 rounded-full bg-red-400"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-yellow-400"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-green-400"></div>
                      </div>
                      <div className="text-xs font-medium text-gray-600">Project Timeline</div>
                    </div>
                    <div className="p-4">
                      <div className="flex justify-between items-center mb-4">
                        <div className="text-sm font-semibold">Tower Project Timeline</div>
                        <div className="flex items-center">
                          <div className="text-xs px-2 py-1 bg-green-100 text-green-700 rounded-full">On Schedule</div>
                        </div>
                      </div>

                      <div className="space-y-4">
                        <div className="relative">
                          <div className="absolute left-3 top-0 bottom-0 w-0.5 bg-gray-200"></div>

                          <div className="relative pl-10 pb-5">
                            <div className="absolute left-0 w-6 h-6 rounded-full bg-green-500 flex items-center justify-center text-white">
                              <Check className="w-3 h-3" />
                            </div>
                            <div className="text-sm font-medium">Planning Phase</div>
                            <div className="text-xs text-gray-500">Completed on June 15, 2023</div>
                            <div className="mt-1 text-xs text-green-600">100% Complete</div>
                          </div>

                          <div className="relative pl-10 pb-5">
                            <div className="absolute left-0 w-6 h-6 rounded-full bg-green-500 flex items-center justify-center text-white">
                              <Check className="w-3 h-3" />
                            </div>
                            <div className="text-sm font-medium">Schematic Design</div>
                            <div className="text-xs text-gray-500">Completed on August 30, 2023</div>
                            <div className="mt-1 text-xs text-green-600">100% Complete</div>
                          </div>

                          <div className="relative pl-10 pb-5">
                            <div className="absolute left-0 w-6 h-6 rounded-full bg-blue-500 flex items-center justify-center text-white">
                              <Clock className="w-3 h-3" />
                            </div>
                            <div className="text-sm font-medium">Design Development</div>
                            <div className="text-xs text-gray-500">Due on March 15, 2024</div>
                            <div className="mt-1">
                              <div className="h-1.5 w-full bg-gray-100 rounded-full">
                                <div className="h-1.5 w-4/5 bg-blue-500 rounded-full"></div>
                              </div>
                              <div className="flex justify-between text-xs mt-1">
                                <span className="text-blue-600">80% Complete</span>
                                <span className="text-gray-500">2 weeks left</span>
                              </div>
                            </div>
                          </div>

                          <div className="relative pl-10 pb-5">
                            <div className="absolute left-0 w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-gray-500">
                              <Clock className="w-3 h-3" />
                            </div>
                            <div className="text-sm font-medium text-gray-500">Construction Documents</div>
                            <div className="text-xs text-gray-500">Due on June 30, 2024</div>
                            <div className="mt-1">
                              <div className="h-1.5 w-full bg-gray-100 rounded-full">
                                <div className="h-1.5 w-1/4 bg-gray-300 rounded-full"></div>
                              </div>
                              <div className="flex justify-between text-xs mt-1">
                                <span className="text-gray-500">25% Complete</span>
                                <span className="text-gray-500">4 months left</span>
                              </div>
                            </div>
                          </div>

                          <div className="relative pl-10">
                            <div className="absolute left-0 w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-gray-500">
                              <Clock className="w-3 h-3" />
                            </div>
                            <div className="text-sm font-medium text-gray-500">Construction Administration</div>
                            <div className="text-xs text-gray-500">Starts on July 15, 2024</div>
                            <div className="mt-1 text-xs text-gray-500">Not Started</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -right-16 top-24 bg-white rounded-lg shadow-lg p-3 transform rotate-6 border border-gray-100">
                    <div className="flex items-center space-x-2">
                      <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center text-green-600">
                        <Check className="w-4 h-4" />
                      </div>
                      <div>
                        <div className="text-xs font-medium">Milestone Completed</div>
                        <div className="text-xs text-gray-500">Schematic Design</div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -left-12 -bottom-6 bg-white rounded-lg shadow-lg p-3 transform -rotate-3 border border-gray-100">
                    <div className="flex items-center space-x-2">
                      <div className="w-8 h-8 rounded-full bg-orange-100 flex items-center justify-center text-orange-600">
                        <Bell className="w-4 h-4" />
                      </div>
                      <div>
                        <div className="text-xs font-medium">Upcoming Deadline</div>
                        <div className="text-xs text-gray-500">Design Review</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section
        id="testimonials"
        className={`py-24 px-6 bg-gradient-to-br from-indigo-50 to-purple-50 relative transition-all duration-1000 transform ${
          isVisible.testimonials ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}
      >
        {/* Background graphics */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
          <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-indigo-300/10 to-purple-300/10 rounded-full blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-1/3 h-1/3 bg-gradient-to-tr from-purple-300/10 to-indigo-300/10 rounded-full blur-3xl"></div>
        </div>

        <div className="container mx-auto relative">
          <div className="text-center mb-16">
            <div className="inline-flex items-center bg-purple-50 text-purple-600 rounded-full px-3 py-1 text-sm font-medium mb-4">
              <Award className="w-4 h-4 mr-1" />
              <span>Client Testimonials</span>
            </div>
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Trusted by Leading Architectural Firms</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Discover why architectural firms of all sizes rely on DesignVault to streamline their projects and improve collaboration.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <TestimonialCard
              quote="DesignVault has revolutionized how we manage our architectural documentation. The version control alone has saved us countless hours of confusion and rework."
              author="Sarah Johnson"
              role="Principal Architect, Johnson Design Group"
              image="/api/placeholder/100/100"
            />
            <TestimonialCard
              quote="The issue tracking system is perfectly tailored for architectural workflows. It's helped us identify and resolve design problems before they become expensive construction mistakes."
              author="Michael Chen"
              role="Project Manager, Urban Spaces Architecture"
              image="/api/placeholder/100/100"
            />
            <TestimonialCard
              quote="As a multi-office firm, team collaboration was always a challenge. DesignVault has bridged that gap, allowing our teams to work together seamlessly regardless of location."
              author="Emma Rodriguez"
              role="Director, Rodriguez + Partners"
              image="/api/placeholder/100/100"
            />
          </div>

          <div className="mt-16 bg-white rounded-3xl shadow-xl border border-gray-100 p-8 md:p-12">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <div className="flex items-center mb-4">
                  {[1, 2, 3, 4, 5].map((i) => (
                    <Star key={i} className="h-6 w-6 text-yellow-400 fill-current" />
                  ))}
                </div>
                <h3 className="text-2xl font-bold mb-4">Join 2,000+ architectural firms already using DesignVault</h3>
                <p className="text-gray-600 mb-6">
                  From small studios to large international practices, DesignVault scales to meet the needs of any architectural team.
                </p>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center mr-3">
                      <Check className="w-3 h-3 text-green-600" />
                    </div>
                    <span className="text-gray-700">14-day free trial, no credit card required</span>
                  </li>
                  <li className="flex items-center">
                    <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center mr-3">
                      <Check className="w-3 h-3 text-green-600" />
                    </div>
                    <span className="text-gray-700">Unlimited projects on all plans</span>
                  </li>
                  <li className="flex items-center">
                    <div className="w-5 h-5 rounded-full bg-green-100 flex items-center justify-center mr-3">
                      <Check className="w-3 h-3 text-green-600" />
                    </div>
                    <span className="text-gray-700">Dedicated support and onboarding</span>
                  </li>
                </ul>
              </div>
              <div className="md:w-1/2 md:pl-12 border-t md:border-t-0 md:border-l border-gray-200 pt-8 md:pt-0 ">
                <h4 className="text-xl font-bold mb-6">Ready to transform your architectural workflow?</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="bg-blue-50 p-4 rounded-xl border border-blue-100">
                    <div className="text-xl font-bold text-blue-600 mb-1">$29</div>
                    <div className="text-sm font-semibold mb-1">Pro Plan</div>
                    <div className="text-xs text-gray-500 mb-3">Per user / month</div>
                    <ul className="text-xs space-y-2 mb-4">
                      <li className="flex items-start">
                        <Check className="w-3 h-3 text-blue-600 mt-0.5 mr-1.5 flex-shrink-0" />
                        <span>All core features</span>
                      </li>
                      <li className="flex items-start">
                        <Check className="w-3 h-3 text-blue-600 mt-0.5 mr-1.5 flex-shrink-0" />
                        <span>50GB storage per user</span>
                      </li>
                      <li className="flex items-start">
                        <Check className="w-3 h-3 text-blue-600 mt-0.5 mr-1.5 flex-shrink-0" />
                        <span>Premium support</span>
                      </li>
                    </ul>
                  </div>
                  <div className="bg-indigo-50 p-4 rounded-xl border border-indigo-100 relative">
                    <div className="absolute -top-3 right-4 bg-indigo-600 text-white text-xs px-2 py-0.5 rounded-full">Popular</div>
                    <div className="text-xl font-bold text-indigo-600 mb-1">$49</div>
                    <div className="text-sm font-semibold mb-1">Enterprise Plan</div>
                    <div className="text-xs text-gray-500 mb-3">Per user / month</div>
                    <ul className="text-xs space-y-2 mb-4">
                      <li className="flex items-start">
                        <Check className="w-3 h-3 text-indigo-600 mt-0.5 mr-1.5 flex-shrink-0" />
                        <span>All Pro features</span>
                      </li>
                      <li className="flex items-start">
                        <Check className="w-3 h-3 text-indigo-600 mt-0.5 mr-1.5 flex-shrink-0" />
                        <span>Unlimited storage</span>
                      </li>
                      <li className="flex items-start">
                        <Check className="w-3 h-3 text-indigo-600 mt-0.5 mr-1.5 flex-shrink-0" />
                        <span>Advanced security</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <button className="mt-4 w-full py-3 rounded-xl bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-medium shadow-lg hover:shadow-xl hover:-translate-y-0.5 transition-all flex items-center justify-center">
                  Start Your Free Trial
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                <div className="text-center text-xs text-gray-500 mt-3">No credit card required</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12 px-6">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-12">
            <div>
              <div className="flex items-center mb-6">
                <div className="w-10 h-10 relative overflow-hidden">
                  <div className="absolute inset-0 bg-gradient-to-tr from-blue-600 to-indigo-600 rounded-lg transform rotate-3"></div>
                  <div className="absolute inset-0 flex items-center justify-center text-white font-bold text-xl">D</div>
                </div>
                <span className="ml-3 text-xl font-extrabold bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent">DesignVault</span>
              </div>
              <p className="text-gray-400 text-sm mb-4">The all-in-one platform for architectural document management and project collaboration.</p>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path
                      fillRule="evenodd"
                      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div>
              <h3 className="font-semibold text-lg mb-4">Product</h3>
              <ul className="space-y-2 text-gray-400">
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Features
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Pricing
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Integrations
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Changelog
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Roadmap
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold text-lg mb-4">Resources</h3>
              <ul className="space-y-2 text-gray-400">
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Help Center
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Documentation
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Guides
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    API Reference
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Community
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-semibold text-lg mb-4">Company</h3>
              <ul className="space-y-2 text-gray-400">
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Careers
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:text-white transition-colors">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-400 text-sm mb-4 md:mb-0">© 2024 DesignVault. All rights reserved.</div>
            <div className="flex space-x-6">
              <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">
                Privacy Policy
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">
                Terms of Service
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors text-sm">
                Cookie Policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

// Feature Card Component for Features Section
const FeatureCard = ({ icon, title, description, color }) => {
  const getGradient = (color) => {
    const gradients = {
      blue: 'from-blue-500 to-blue-600',
      indigo: 'from-indigo-500 to-indigo-600',
      violet: 'from-violet-500 to-violet-600',
      purple: 'from-purple-500 to-purple-600',
      pink: 'from-pink-500 to-pink-600',
      rose: 'from-rose-500 to-rose-600',
    };
    return gradients[color] || 'from-blue-500 to-blue-600';
  };

  return (
    <div className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100 hover:shadow-xl hover:-translate-y-1 transition-all">
      <div className={`w-12 h-12 rounded-lg bg-gradient-to-br ${getGradient(color)} text-white flex items-center justify-center mb-5`}>{icon}</div>
      <h3 className="text-xl font-bold mb-3">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

// Feature Item Component for Section Details
const FeatureItem = ({ icon, title, description }) => {
  return (
    <div className="flex items-start">
      <div className="w-10 h-10 rounded-lg bg-blue-100 text-blue-600 flex items-center justify-center mr-4 flex-shrink-0">{icon}</div>
      <div>
        <h3 className="font-semibold mb-1">{title}</h3>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
    </div>
  );
};

// Testimonial Card Component
const TestimonialCard = ({ quote, author, role, image }) => {
  return (
    <div className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100 hover:shadow-xl hover:-translate-y-1 transition-all">
      <div className="flex items-center mb-4">
        {[1, 2, 3, 4, 5].map((i) => (
          <Star key={i} className="h-4 w-4 text-yellow-400 fill-current" />
        ))}
      </div>
      <p className="text-gray-700 mb-6">{quote}</p>
      <div className="flex items-center">
        <div className="w-12 h-12 rounded-full overflow-hidden mr-3">
          <img src={image} alt={author} className="w-full h-full object-cover" />
        </div>
        <div>
          <div className="font-semibold">{author}</div>
          <div className="text-gray-500 text-sm">{role}</div>
        </div>
      </div>
    </div>
  );
};

export default DesignVaultPremium;
