import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

const FactSection = ({ title, items }) => {
  const validItems = items.filter(([label, value]) => label !== undefined && label !== null);

  if (validItems.length === 0) return null;

  return (
    <div className="border-t border-gray-100/50 backdrop-blur-sm">
      <h3 className="text-base font-semibold leading-7 bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] bg-clip-text text-transparent px-6 py-5">{title}</h3>
      <dl className="divide-y divide-gray-100/50">
        {validItems.map(([label, value], index) => (
          <div key={index} className="px-6 py-4 transition-colors duration-200 hover:bg-gray-50/50 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-600">{label}</dt>
            <dd className="mt-1 text-sm text-gray-800 sm:col-span-2 sm:mt-0">
              {typeof value === 'boolean' ? (
                value ? (
                  <span className="inline-flex items-center px-3 py-1 rounded-full bg-green-50 text-green-700">
                    <CheckIcon className="h-4 w-4 text-green-500 mr-2" />
                    Yes
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-1 rounded-full bg-gray-50 text-gray-700">No</span>
                )
              ) : (
                <div className="whitespace-pre-line">{value || <span className="text-gray-400 italic">Not provided</span>}</div>
              )}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

const ProjectFactSheet = ({ project }) => {
  if (!project) {
    return (
      <div className="bg-white p-6">
        <p className="text-gray-500">No project data available</p>
      </div>
    );
  }

  const formatBudgetRange = (budgetType) => {
    const budgetRanges = {
      '50000-75000': '£50,000 - £75,000',
      '75000-100000': '£75,000 - £100,000',
      '100000-125000': '£100,000 - £125,000',
      '125000-150000': '£125,000 - £150,000',
      '150000-175000': '£150,000 - £175,000',
      '175000-200000': '£175,000 - £200,000',
      '200000-250000': '£200,000 - £250,000',
      '250000plus': '£250,000 +',
      unknown: 'Unknown',
    };
    return budgetRanges[budgetType] || 'Not specified';
  };

  const formatCurrency = (amount) => {
    if (!amount && amount !== 0) return null;
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const getBudgetDisplay = (budgetType, customBudget) => {
    if (!budgetType) return null;
    if (budgetType === 'custom' && (customBudget || customBudget === 0)) {
      return formatCurrency(customBudget);
    }
    return formatBudgetRange(budgetType);
  };

  const propertyDetails = [
    ['Property Type', project?.type],
    ['Property Address', project?.propertyAddress],
    ['Property Age', project?.age],
    ['Number of Bedrooms', project?.bedrooms],
    ['Property Style', project?.style],
    ['Property Condition', project?.condition],
    ['Council Authority', project?.councilAuthorityName],
    ['Online Link', project?.onlineLink],
  ];

  const projectDetails = [
    ['Project Manager', project?.projectManager],
    ['Project Stage', project?.projectStage],
    ['Project Style', project?.projectStyle],
    ['Style Comments', project?.styleComments],
    ['Project Status', project?.projectStatus],
    ['Number of Phases', project?.numberOfPhases],
    project?.budgetType ? ['Budget Range', formatBudgetRange(project.budgetType)] : null,
  ].filter(Boolean);

  const formatItems = (obj, skipEmpty = true) => {
    if (!obj) return [];

    return Object.entries(obj)
      .filter(([_, value]) => !skipEmpty || value !== false)
      .map(([key, value]) => {
        const formatted = key
          .replace(/([A-Z])/g, ' $1')
          .toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase());
        return [formatted, value];
      });
  };

  const additionalSpaceItems = formatItems(project?.additionalInfo?.additionalSpace);
  const servicesItems = formatItems(project?.additionalInfo?.services);
  const decorationItems = formatItems(project?.additionalInfo?.decoration);
  const layoutItems = formatItems(project?.additionalInfo?.internalLayoutReconfiguration);

  const formatDate = (dateString) => {
    if (!dateString) return null;
    try {
      return new Date(dateString).toLocaleDateString();
    } catch (error) {
      console.error('Invalid date:', dateString);
      return null;
    }
  };

  const timelineDetails = [
    ['Start Date', formatDate(project?.projectStartDate)],
    ['End Date', formatDate(project?.projectEndDate)],
    ['Timeline Description', project?.projectTimelineDescription],
  ];

  const budgetDetails =
    project?.phases?.length > 0
      ? [] // Empty array if phases exist
      : [
          ['Budget', getBudgetDisplay(project?.budgetType, project?.customBudget)],
          ['Further Budget Information', project?.furtherBudgetInfo],
        ];

  const phaseDetails = project?.phases
    ?.filter((phase) => phase.name || phase.budgetType || phase.customBudget)
    ?.flatMap((phase, index) => [
      [`Phase ${index + 1} Name`, phase.name || 'Unnamed Phase'],
      [`Phase ${index + 1} Budget`, getBudgetDisplay(phase.budgetType, phase.customBudget)],
    ]);

  return (
    <div className="bg-white/80 backdrop-blur-lg rounded-2xl overflow-hidden border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]">
      <div className="px-6 py-8">
        <h3 className="text-2xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] bg-clip-text text-transparent">Project Fact Sheet</h3>
        <p className="mt-2 text-sm text-gray-500">Detailed information about the project</p>
      </div>

      <div className="divide-y divide-gray-100/50">
        <FactSection title="Property Details" items={propertyDetails} />
        <FactSection title="Project Details" items={projectDetails} />

        {additionalSpaceItems.length > 0 && <FactSection title="Additional Space Requirements" items={additionalSpaceItems} />}

        {layoutItems.length > 0 && <FactSection title="Internal Layout Reconfiguration" items={layoutItems} />}

        {servicesItems.length > 0 && <FactSection title="Required Services" items={servicesItems} />}

        {decorationItems.length > 0 && <FactSection title="Decoration Requirements" items={decorationItems} />}

        <FactSection title="Timeline Information" items={timelineDetails} />

        {budgetDetails.length > 0 && <FactSection title="Budget Information" items={budgetDetails} />}

        {phaseDetails?.length > 0 && <FactSection title="Phased Budget Information" items={phaseDetails} />}

        {project?.specialRequirements && (
          <div className="border-t border-gray-100/50">
            <div className="px-6 py-5">
              <h3 className="text-base font-semibold leading-7 bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] bg-clip-text text-transparent">
                Special Requirements
              </h3>
              <div className="mt-4 text-sm text-gray-800 whitespace-pre-wrap p-4 bg-gray-50/50 rounded-lg">{project.specialRequirements}</div>
            </div>
          </div>
        )}

        {project?.additionalInfo?.furtherDetails && (
          <div className="border-t border-gray-100/50">
            <div className="px-6 py-5">
              <h3 className="text-base font-semibold leading-7 bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] bg-clip-text text-transparent">
                Additional Information
              </h3>
              <div className="mt-4 text-sm text-gray-800 whitespace-pre-wrap p-4 bg-gray-50/50 rounded-lg">{project.additionalInfo.furtherDetails}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectFactSheet;
