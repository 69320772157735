import React, { useState, useEffect, useCallback } from 'react';
import { PhotoIcon, XMarkIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Loader2 } from 'lucide-react';
import ImageUploader from '../Images/ImageUploader';
import { imageService } from '../../../services/imageService';
import { toast } from 'react-hot-toast';
import Modal from '../Images/Modal';
import { motion, AnimatePresence } from 'framer-motion';

const NoteImages = ({ images, onImagesChange, projectId, isEditing }) => {
  const [showImageSelector, setShowImageSelector] = useState(false);
  const [projectImages, setProjectImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadingStates, setLoadingStates] = useState({
    downloading: new Set(),
    removing: new Set(),
    selecting: new Set(),
  });
  const [imageToDelete, setImageToDelete] = useState(null);

  const fetchProjectImages = async () => {
    try {
      setIsLoading(true);
      const fetchedImages = await imageService.getImages(projectId);
      setProjectImages(fetchedImages);
    } catch (error) {
      console.error('Error fetching images:', error);
      toast.error('Failed to load images');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = async (acceptedFiles) => {
    try {
      const file = acceptedFiles[0];
      if (!file) return;

      setIsUploading(true);
      const formData = new FormData();
      formData.append('image', file);

      const newImage = await imageService.uploadImage(projectId, formData);
      const simplifiedImage = {
        _id: newImage._id,
        filename: newImage.filename,
        signedUrl: newImage.signedUrl,
      };
      onImagesChange([...images, simplifiedImage]);
      toast.success('Image uploaded successfully');
    } catch (error) {
      console.error('Failed to upload image:', error);
      toast.error('Failed to upload image');
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemoveImage = async (imageId) => {
    setLoadingStates((prev) => ({
      ...prev,
      removing: new Set([...prev.removing, imageId]),
    }));
    try {
      await onImagesChange(images.filter((img) => img._id !== imageId));
      toast.success('Image removed');
    } catch (error) {
      toast.error('Failed to remove image');
    } finally {
      setLoadingStates((prev) => {
        const newRemoving = new Set(prev.removing);
        newRemoving.delete(imageId);
        return { ...prev, removing: newRemoving };
      });
    }
    setImageToDelete(null);
  };

  const handleSelectExistingImage = async (image) => {
    if (images.some((img) => img._id === image._id)) return;

    setLoadingStates((prev) => ({
      ...prev,
      selecting: new Set([...prev.selecting, image._id]),
    }));
    try {
      const simplifiedImage = {
        _id: image._id,
        filename: image.filename,
        signedUrl: image.signedUrl,
      };
      await onImagesChange([...images, simplifiedImage]);
      setShowImageSelector(false);
      toast.success('Image added to note');
    } catch (error) {
      toast.error('Failed to add image');
    } finally {
      setLoadingStates((prev) => {
        const newSelecting = new Set(prev.selecting);
        newSelecting.delete(image._id);
        return { ...prev, selecting: newSelecting };
      });
    }
  };

  const handleDownload = async (image) => {
    setLoadingStates((prev) => ({
      ...prev,
      downloading: new Set([...prev.downloading, image._id]),
    }));
    try {
      await imageService.downloadImage(image._id, image.filename);
      toast.success('Image downloaded successfully');
    } catch (error) {
      console.error('Failed to download image:', error);
      toast.error('Failed to download image');
    } finally {
      setLoadingStates((prev) => {
        const newDownloading = new Set(prev.downloading);
        newDownloading.delete(image._id);
        return { ...prev, downloading: newDownloading };
      });
    }
  };

  const handleImageClick = (image) => {
    const modalImage = {
      _id: image._id,
      filename: image.filename,
      signedUrl: image.signedUrl,
    };
    setSelectedImage(modalImage);
  };

  return (
    <div className="mt-12 pt-8 border-t border-gray-100">
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl font-semibold text-gray-900">Images</h2>
        {isEditing && (
          <button
            onClick={() => {
              setShowImageSelector(true);
              fetchProjectImages();
            }}
            disabled={isUploading}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-[#2A9D8F] rounded-full 
                     hover:bg-[#238276] transition-colors duration-200 shadow-sm hover:shadow
                     disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <PhotoIcon className="w-4 h-4 mr-1.5" />
            Add Images
          </button>
        )}
      </div>

      {isEditing && (
        <div className="relative mb-8">
          <ImageUploader
            onDrop={handleImageUpload}
            disabled={isUploading}
            className="border-2 border-dashed border-gray-200 hover:border-[#2A9D8F] transition-colors rounded-xl bg-gray-50/50"
          />
          {isUploading && (
            <div className="absolute inset-0 backdrop-blur-sm bg-white/60 rounded-xl flex items-center justify-center">
              <div className="flex flex-col items-center">
                <Loader2 className="w-5 h-5 text-[#2A9D8F] animate-spin" />
                <p className="mt-3 text-sm text-gray-600">Uploading...</p>
              </div>
            </div>
          )}
        </div>
      )}

      {images.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-8 text-center">
          <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-gray-100 mb-4">
            <PhotoIcon className="w-6 h-6 text-gray-400" />
          </div>
          <p className="text-sm text-gray-500">
            {isEditing ? 'No images yet. Drop images above or click Add Images to browse.' : 'No images have been added to this note yet.'}
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image) => (
            <div key={image._id} className="relative group">
              <div className="relative aspect-square overflow-hidden rounded-xl cursor-pointer bg-gray-50/50" onClick={() => handleImageClick(image)}>
                <img
                  src={image.signedUrl}
                  alt={image.filename}
                  className="w-full h-full object-cover transform group-hover:scale-[1.02] transition-transform duration-200"
                />
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-5 transition-opacity" />
                {isEditing && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setImageToDelete(image._id);
                    }}
                    disabled={loadingStates.removing.has(image._id)}
                    className="absolute top-2 right-2 p-1.5 bg-white/90 hover:bg-white text-gray-700 rounded-lg 
                             opacity-0 group-hover:opacity-100 transition-all duration-200 shadow-sm
                             disabled:cursor-not-allowed"
                  >
                    {loadingStates.removing.has(image._id) ? <Loader2 className="w-4 h-4 animate-spin" /> : <XMarkIcon className="w-4 h-4" />}
                  </button>
                )}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownload(image);
                  }}
                  disabled={loadingStates.downloading.has(image._id)}
                  className="absolute top-2 left-2 p-1.5 bg-white/90 hover:bg-white text-gray-700 rounded-lg 
                           opacity-0 group-hover:opacity-100 transition-all duration-200 shadow-sm
                           disabled:cursor-not-allowed"
                >
                  {loadingStates.downloading.has(image._id) ? <Loader2 className="w-4 h-4 animate-spin" /> : <ArrowDownTrayIcon className="w-4 h-4" />}
                </button>
              </div>
              <p className="mt-2 text-sm text-gray-500 truncate">{image.filename}</p>
            </div>
          ))}
        </div>
      )}

      {showImageSelector && (
        <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-xl max-w-4xl w-full max-h-[80vh] overflow-hidden shadow-xl mx-4">
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-lg font-semibold text-gray-900">Project Images</h3>
              <button onClick={() => setShowImageSelector(false)} className="p-1.5 hover:bg-gray-100 rounded-lg transition-colors">
                <XMarkIcon className="w-5 h-5" />
              </button>
            </div>
            <div className="p-4 overflow-y-auto max-h-[60vh]">
              {isLoading ? (
                <div className="flex items-center justify-center py-12">
                  <Loader2 className="w-5 h-5 text-[#2A9D8F] animate-spin" />
                </div>
              ) : (
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {projectImages.map((image) => (
                    <div
                      key={image._id}
                      onClick={() => !loadingStates.selecting.has(image._id) && handleSelectExistingImage(image)}
                      className={`relative cursor-pointer group aspect-square ${
                        images.some((img) => img._id === image._id) ? 'ring-2 ring-[#2A9D8F] ring-offset-2' : 'hover:opacity-90'
                      } ${loadingStates.selecting.has(image._id) ? 'cursor-not-allowed' : ''}`}
                    >
                      <div className="relative h-full rounded-xl overflow-hidden bg-gray-50/50">
                        <img
                          src={image.signedUrl}
                          alt={image.filename}
                          className="w-full h-full object-cover transform group-hover:scale-[1.02] transition-transform duration-200"
                        />
                        {loadingStates.selecting.has(image._id) && (
                          <div className="absolute inset-0 bg-white/60 flex items-center justify-center">
                            <Loader2 className="w-5 h-5 text-[#2A9D8F] animate-spin" />
                          </div>
                        )}
                        {images.some((img) => img._id === image._id) && (
                          <div className="absolute inset-0 bg-[#2A9D8F]/5 flex items-center justify-center">
                            <span className="bg-white px-3 py-1.5 rounded-full text-sm font-medium text-[#2A9D8F] shadow-sm">Selected</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {selectedImage && <Modal currentImage={selectedImage} handleCloseModal={() => setSelectedImage(null)} />}

      <AnimatePresence>
        {imageToDelete && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/25 backdrop-blur-sm z-50 flex items-center justify-center"
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="bg-white rounded-xl shadow-xl p-6 m-4 max-w-sm w-full"
            >
              <h3 className="text-lg font-medium text-gray-900 mb-4">Remove Image</h3>
              <p className="text-sm text-gray-500 mb-6">
                Are you sure you want to remove this image from the note? This won't delete the image from your project.
              </p>
              <div className="flex justify-end gap-4">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setImageToDelete(null)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800 
                           transition-colors duration-200 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => handleRemoveImage(imageToDelete)}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 
                           hover:bg-red-700 rounded-md transition-colors duration-200
                           shadow-sm hover:shadow"
                >
                  Remove
                </motion.button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NoteImages;
