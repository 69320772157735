import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import OrganisationProfile from '../Organisations/Profile';
import AddNewMember from '../Organisations/AddNewMembers';
import OrganisationMembers from '../Organisations/Members';
import OrganisationContacts from '../Organisations/Contacts';
import OrganisationClients from '../Organisations/Clients';
import MilestoneTemplates from '../Organisations/MilestoneTemplates';
import organizationService from '../../services/organizationService.js';
import api from '../../utils/api';

const secondaryNavigation = [
  { name: 'Profile', href: '#profile', current: true },
  { name: 'Members', href: '#members', current: false },
  { name: 'Contacts', href: '#contacts', current: false },
  { name: 'Clients', href: '#clients', current: false },
  { name: 'Milestone Templates', href: '#milestone-templates', current: false },
];

export default function OrganisationPage() {
  const { userContextData } = useContext(UserContext);
  const [organisation, setOrganisation] = useState({
    name: '',
    address: '',
    email: '',
    number: '',
    website: '',
    companyNumber: '',
    vatNumber: '',
    members: [],
    contacts: [],
  });
  const [organisationClients, setOrganisationClients] = useState([]);
  const [activeSection, setActiveSection] = useState('profile');

  useEffect(() => {
    const fetchOrganisation = async () => {
      const data = await organizationService.getOrganization(userContextData.organisation);
      setOrganisation((prevDetails) => ({
        ...prevDetails,
        ...data,
      }));
    };

    if (userContextData.organisation) {
      fetchOrganisation();
    }
  }, [userContextData.organisation]);

  useEffect(() => {
    const fetchOrganisationContacts = async () => {
      const contacts = await organizationService.getOrganizationContacts(userContextData.organisation);
      setOrganisation((prevDetails) => ({
        ...prevDetails,
        contacts: contacts,
      }));
    };

    if (userContextData.organisation) {
      fetchOrganisationContacts();
    }
  }, [userContextData.organisation]);

  useEffect(() => {
    const fetchOrganisationClients = async () => {
      const clients = await organizationService.getOrganizationClients(userContextData.organisation);
      setOrganisationClients(clients);
    };

    if (userContextData.organisation) {
      fetchOrganisationClients();
    }
  }, [userContextData.organisation]);

  const handleRemoveMember = async (userId) => {
    const updatedOrganisation = await organizationService.removeMember(userContextData.organisation, userId);
    setOrganisation(updatedOrganisation);
  };

  const isOwner = userContextData.role === 'admin';

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Secondary navigation */}
      <nav className="flex overflow-x-auto border-b border-gray-200 py-4">
        <ul className="flex min-w-full flex-none gap-x-6 text-sm font-semibold leading-6 text-gray-600">
          {secondaryNavigation.map((item) => (
            <li key={item.name}>
              <a
                href={item.href}
                className={`${activeSection === item.href.slice(1) ? 'text-[#2A9D8F]' : 'text-gray-600'} hover:text-[#2A9D8F] transition-colors duration-200`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveSection(item.href.slice(1));
                }}
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <div className="mt-8 space-y-10">
        {activeSection === 'profile' && (
          <OrganisationProfile organisation={organisation} setOrganisation={setOrganisation} isOwner={isOwner} userContextData={userContextData} />
        )}

        {activeSection === 'members' && (
          <>
            {isOwner && <AddNewMember userContextData={userContextData} setOrganisation={setOrganisation} />}
            <OrganisationMembers organisation={organisation} isOwner={isOwner} userContextData={userContextData} handleRemoveMember={handleRemoveMember} />
          </>
        )}

        {activeSection === 'contacts' && <OrganisationContacts contacts={organisation.contacts} />}

        {activeSection === 'clients' && <OrganisationClients clients={organisationClients} />}

        {activeSection === 'milestone-templates' && <MilestoneTemplates organisation={organisation} />}
      </div>
    </div>
  );
}
