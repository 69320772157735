import React from 'react';
import CustomMilestoneForm from './CustomMilestoneForm';

export const MilestoneForm = ({
  projectId,
  onMilestoneAdded,
  newMilestoneTitle,
  setNewMilestoneTitle,
  newMilestoneCategory,
  setNewMilestoneCategory,
  newMilestoneDeadline,
  setNewMilestoneDeadline,
}) => {
  const handleSubmit = async (milestoneData) => {
    await onMilestoneAdded(milestoneData);
  };

  return (
    <div>
      <h2 className="font-medium text-gray-900">Add Key Milestone</h2>
      <p className="mt-1 text-sm text-gray-600">Enter details for a new milestone</p>
      <div className="mt-4">
        <CustomMilestoneForm
          projectId={projectId}
          onMilestoneAdded={handleSubmit}
          newMilestoneTitle={newMilestoneTitle}
          setNewMilestoneTitle={setNewMilestoneTitle}
          newMilestoneCategory={newMilestoneCategory}
          setNewMilestoneCategory={setNewMilestoneCategory}
          newMilestoneDeadline={newMilestoneDeadline}
          setNewMilestoneDeadline={setNewMilestoneDeadline}
        />
      </div>
    </div>
  );
};

export default MilestoneForm;
