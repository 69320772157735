import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const DropdownMenu = ({ children, isOpen, onClose, triggerRef }) => (
  <AnimatePresence>
    {isOpen && (
      <>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="fixed inset-0 z-[100]" onClick={onClose} />
        <motion.div
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -5 }}
          transition={{ duration: 0.2 }}
          style={{
            position: 'absolute',
            top: triggerRef.current ? triggerRef.current.offsetHeight + 4 : 0,
            left: triggerRef.current ? triggerRef.current.offsetLeft : 0,
          }}
          className="w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[101]"
        >
          <div className="py-1">{children}</div>
        </motion.div>
      </>
    )}
  </AnimatePresence>
);

export default DropdownMenu;
