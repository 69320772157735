import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';
import {
  ChevronUpIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  TrashIcon,
  ArchiveBoxIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  ChevronDownIcon,
  ClipboardDocumentListIcon,
  EllipsisHorizontalIcon,
  EyeIcon,
  EyeSlashIcon,
  StarIcon,
  StarIcon as StarIconSolid,
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { documentService } from '../../../services/documentService';
import DocumentIcon from './DocumentIcon'; // We'll need to extract this as well
import DocumentViewerModal from './DocumentViewerModal';
import DropdownMenu from '../../Common/DropdownMenu';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';

// Move this to a shared utils file if possible
export const formatDate = (dateString) => {
  if (!dateString) return 'Unknown date';

  try {
    const date = new Date(dateString);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }
    return formatDistanceToNow(date, {
      addSuffix: true,
      includeSeconds: true,
      future: true, // Explicitly handle future dates
    });
  } catch (error) {
    console.warn('Error formatting date:', error);
    return 'Invalid date';
  }
};

// rename
const ActionItemDropdownMenu = ({ isOpen, onClose, children, popperProps }) => {
  // Always render the component, but conditionally show its contents
  const portalContent = (
    <>
      {isOpen && (
        <>
          <div className="fixed inset-0 z-30" onClick={onClose} />
          <div {...popperProps} className="z-50">
            {children}
          </div>
        </>
      )}
    </>
  );

  return ReactDOM.createPortal(portalContent, document.body);
};

const ActionCell = ({
  document,
  onArchive,
  onUnarchive,
  onDelete,
  onVisibilityToggle,
  onImportanceToggle,
  onSendApprovalReminder,
  isLoading,
  isOpen,
  onToggle,
  userRole,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
          boundary: 'viewport',
        },
      },
    ],
  });

  console.log('userRole', userRole);

  return (
    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
      <div className="relative inline-block dropdown-container" onClick={(e) => e.stopPropagation()}>
        <motion.button
          ref={setReferenceElement}
          whileHover={{ backgroundColor: 'rgba(42, 157, 143, 0.1)' }}
          whileTap={{ scale: 0.98 }}
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
          className="p-2 rounded-lg text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <EllipsisHorizontalIcon className="w-5 h-5" />
        </motion.button>

        <ActionItemDropdownMenu
          isOpen={isOpen}
          onClose={() => onToggle()}
          popperProps={{
            ref: setPopperElement,
            style: styles.popper,
            ...attributes.popper,
          }}
        >
          <div className="py-1 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 min-w-[12rem]" onClick={(e) => e.stopPropagation()}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onImportanceToggle(document);
              }}
              disabled={isLoading}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:text-[#2A9D8F] hover:bg-[#2A9D8F]/10 disabled:opacity-50 transition-colors"
            >
              {document.isImportant ? (
                <>
                  <StarIconSolid className="mr-3 h-4 w-4 text-yellow-400" />
                  Remove Importance
                </>
              ) : (
                <>
                  <StarIcon className="mr-3 h-4 w-4" />
                  Mark as Important
                </>
              )}
            </button>
            {userRole === 'admin' && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onVisibilityToggle(document);
                }}
                disabled={isLoading}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:text-[#2A9D8F] hover:bg-[#2A9D8F]/10 disabled:opacity-50 transition-colors"
              >
                {document.visibleToClient ? (
                  <>
                    <EyeSlashIcon className="mr-3 h-4 w-4" />
                    Hide from Client
                  </>
                ) : (
                  <>
                    <EyeIcon className="mr-3 h-4 w-4" />
                    Show to Client
                  </>
                )}
              </button>
            )}
            {!document.approvedByClient && userRole === 'admin' && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onSendApprovalReminder(document);
                }}
                disabled={isLoading}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:text-[#2A9D8F] hover:bg-[#2A9D8F]/10 disabled:opacity-50 transition-colors"
              >
                <ClipboardDocumentListIcon className="mr-3 h-4 w-4" />
                Send Approval Reminder
              </button>
            )}
            {document.isArchived ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onUnarchive(document);
                }}
                disabled={isLoading}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:text-[#2A9D8F] hover:bg-[#2A9D8F]/10 disabled:opacity-50 transition-colors"
              >
                <ArchiveBoxIcon className="mr-3 h-4 w-4" />
                Unarchive
              </button>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onArchive(document);
                }}
                disabled={isLoading}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:text-[#2A9D8F] hover:bg-[#2A9D8F]/10 disabled:opacity-50 transition-colors"
              >
                <ArchiveBoxIcon className="mr-3 h-4 w-4" />
                Archive
              </button>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(document);
              }}
              disabled={isLoading}
              className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-red-50 disabled:opacity-50 transition-colors"
            >
              <TrashIcon className="mr-3 h-4 w-4" />
              Delete
            </button>
          </div>
        </ActionItemDropdownMenu>
      </div>
    </td>
  );
};

const DocumentTable = ({ documents, onDocumentUpdate, userRole }) => {
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: 'uploadedDate', direction: 'desc' });
  const { projectId } = useParams();
  const [loadingStates, setLoadingStates] = useState({});
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [filters, setFilters] = useState({
    status: 'all',
    ...(userRole === 'admin' ? { visibility: 'all' } : {}),
    importance: 'all',
  });
  const [activeActionDropdown, setActiveActionDropdown] = useState(null);
  const actionRefs = useRef({});

  // Initialize all refs unconditionally
  const statusFilterRef = useRef(null);
  const visibilityFilterRef = useRef(null);
  const importanceFilterRef = useRef(null);

  // Group refs into an object after initialization
  const filterRefs = {
    status: statusFilterRef,
    ...(userRole === 'admin' ? { visibility: visibilityFilterRef } : {}),
    importance: importanceFilterRef,
  };

  const filterOptions = {
    status: [
      { value: 'all', label: 'All Statuses' },
      { value: 'approved', label: 'Approved' },
      { value: 'pending', label: 'Pending Approval' },
      { value: 'archived', label: 'Archived' },
    ],
    ...(userRole === 'admin' && {
      visibility: [
        { value: 'all', label: 'All Visibility' },
        { value: 'visible', label: 'Client Visible' },
        { value: 'hidden', label: 'Client Hidden' },
      ],
    }),
    importance: [
      { value: 'all', label: 'All Importance' },
      { value: 'important', label: 'Important' },
      { value: 'normal', label: 'Normal' },
    ],
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeDropdown && !event.target.closest('.dropdown-container')) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [activeDropdown]);

  const filteredAndSortedDocuments = useMemo(() => {
    // First filter for client visibility if not admin
    const visibilityFiltered = userRole === 'admin' ? documents : documents.filter((doc) => doc.visibleToClient);

    return visibilityFiltered
      .filter((doc) => {
        const matchesSearch = searchQuery === '' || doc.name?.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesStatus =
          filters.status === 'all' ||
          (filters.status === 'approved' && doc.approvedByClient) ||
          (filters.status === 'pending' && !doc.approvedByClient) ||
          (filters.status === 'archived' && doc.isArchived);

        // Only apply visibility filter for admin users
        const matchesVisibility =
          userRole === 'admin'
            ? filters.visibility === 'all' ||
              (filters.visibility === 'visible' && doc.visibleToClient) ||
              (filters.visibility === 'hidden' && !doc.visibleToClient)
            : true;

        const matchesImportance =
          filters.importance === 'all' || (filters.importance === 'important' && doc.isImportant) || (filters.importance === 'normal' && !doc.isImportant);

        return matchesSearch && matchesStatus && matchesVisibility && matchesImportance;
      })
      .sort((a, b) => {
        if (sortConfig.key === 'uploadedDate') {
          const dateA = new Date(a[sortConfig.key]);
          const dateB = new Date(b[sortConfig.key]);
          return sortConfig.direction === 'desc' ? dateB - dateA : dateA - dateB;
        }

        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (sortConfig.direction === 'asc') {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        } else {
          return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
        }
      });
  }, [documents, searchQuery, filters, sortConfig, userRole]);

  const columns = [
    { key: 'name', label: 'Document' },
    { key: 'category', label: 'Folder' },
    { key: 'notes', label: 'Notes' },
    { key: 'uploadedDate', label: 'Uploaded' },
    { key: 'actions', label: 'Actions' },
  ];

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(filteredAndSortedDocuments.length / itemsPerPage);

  const currentDocuments = filteredAndSortedDocuments.slice(startIndex, endIndex);

  const handleArchive = async (document) => {
    try {
      setLoadingStates((prev) => ({ ...prev, [document._id]: true }));
      const response = await documentService.archiveDocument(projectId, document._id);

      // Call onDocumentUpdate with the updated document
      onDocumentUpdate(response.document);
      toast.success('Document archived successfully', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      console.error('Error archiving document:', error);
      toast.error('Failed to archive document', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setLoadingStates((prev) => ({ ...prev, [document._id]: false }));
      setActiveActionDropdown(null);
    }
  };

  const handleUnarchive = async (document) => {
    try {
      setLoadingStates((prev) => ({ ...prev, [document._id]: true }));
      const response = await documentService.unarchiveDocument(projectId, document._id);

      // Call onDocumentUpdate with the updated document
      onDocumentUpdate(response.document);
      toast.success('Document unarchived successfully', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      console.error('Error unarchiving document:', error);
      toast.error('Failed to unarchive document', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setLoadingStates((prev) => ({ ...prev, [document._id]: false }));
      setActiveActionDropdown(null);
    }
  };

  const handleDelete = async (document) => {
    try {
      setLoadingStates((prev) => ({ ...prev, [document._id]: true }));
      await documentService.deleteDocument(projectId, document._id);
      const updatedDocuments = documents.filter((doc) => doc._id !== document._id);
      onDocumentUpdate(updatedDocuments);
      toast.success('Document deleted successfully', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      toast.error('Failed to delete document', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setLoadingStates((prev) => ({ ...prev, [document._id]: false }));
      setActiveActionDropdown(null);
    }
  };

  const handleVisibilityToggle = async (document) => {
    try {
      setLoadingStates((prev) => ({ ...prev, [document._id]: true }));

      // Update the document visibility
      const updatedDoc = await documentService.updateDocument(projectId, document._id, {
        visibleToClient: !document.visibleToClient,
      });

      // Create a complete updated document object
      const completeUpdatedDoc = {
        ...document,
        ...updatedDoc,
        visibleToClient: !document.visibleToClient,
        _id: document._id, // Ensure _id is preserved
        uploadedBy: document.uploadedBy, // Preserve the uploadedBy information
      };

      // Pass the complete updated document to the parent
      onDocumentUpdate(completeUpdatedDoc);

      toast.success(`Document is now ${completeUpdatedDoc.visibleToClient ? 'visible' : 'hidden'} to client`, {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      console.error('Error updating document visibility:', error);
      toast.error('Failed to update document visibility', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setLoadingStates((prev) => ({ ...prev, [document._id]: false }));
      setActiveActionDropdown(null);
    }
  };

  const handleImportanceToggle = async (document) => {
    try {
      setLoadingStates((prev) => ({ ...prev, [document._id]: true }));

      // Update the document importance
      const updatedDoc = await documentService.updateDocument(projectId, document._id, {
        isImportant: !document.isImportant,
      });

      // Create a complete updated document object
      const completeUpdatedDoc = {
        ...document,
        ...updatedDoc,
        isImportant: !document.isImportant,
        _id: document._id,
        uploadedBy: document.uploadedBy,
      };

      // Pass the complete updated document to the parent
      onDocumentUpdate(completeUpdatedDoc);

      toast.success(`Document is now ${completeUpdatedDoc.isImportant ? 'marked as important' : 'marked as normal'}`, {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      console.error('Error updating document importance:', error);
      toast.error('Failed to update document importance', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setLoadingStates((prev) => ({ ...prev, [document._id]: false }));
      setActiveActionDropdown(null);
    }
  };

  const handleSendApprovalReminder = async (document) => {
    try {
      setLoadingStates((prev) => ({ ...prev, [document._id]: true }));
      await documentService.sendApprovalReminder(projectId, document._id);
      toast.success('Approval reminder email sent successfully', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      console.error('Error sending approval reminder:', error);
      toast.error('Failed to send approval reminder email', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } finally {
      setLoadingStates((prev) => ({ ...prev, [document._id]: false }));
      setActiveActionDropdown(null);
    }
  };

  const handleRowClick = (document, event) => {
    if (event.target.closest('.dropdown-container')) {
      return;
    }
    setSelectedDocument(document);
    setIsViewerOpen(true);
  };

  const renderStatusBadges = (doc) => {
    const badges = [];

    if (doc.approvedByClient) {
      badges.push({
        label: 'Approved',
        className: 'bg-green-50 text-green-700 border-green-200',
      });
    }

    if (doc.isArchived) {
      badges.push({
        label: 'Archived',
        className: 'bg-gray-50 text-gray-600 border-gray-200',
      });
    }

    if (doc.isImportant) {
      badges.push({
        label: 'Important',
        className: 'bg-yellow-50 text-yellow-700 border-yellow-200',
      });
    }

    if (userRole === 'admin') {
      badges.push({
        label: doc.visibleToClient ? 'Client Visible' : 'Client Hidden',
        className: doc.visibleToClient ? 'bg-blue-50 text-blue-700 border-blue-200' : 'bg-red-50 text-red-700 border-red-200',
      });
    }

    return (
      <div className="flex flex-wrap gap-1 mt-1">
        {badges.map((badge, index) => (
          <span key={index} className={`inline-flex items-center px-1.5 py-0.5 text-[10px] font-medium border rounded-md ${badge.className}`}>
            {badge.label}
          </span>
        ))}
      </div>
    );
  };

  return (
    <>
      <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-4 space-y-4">
          {/* Title and count */}
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">All Documents</h2>
              <p className="text-sm text-gray-500 mt-1">
                {filteredAndSortedDocuments.length} {filteredAndSortedDocuments.length === 1 ? 'document' : 'documents'} total
              </p>
            </div>
          </div>

          {/* Search and Filters */}
          <div className="flex items-center gap-3">
            {/* Search Bar */}
            <div className="relative flex-1">
              <div className="relative">
                <MagnifyingGlassIcon className="absolute left-4 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                <motion.input
                  initial={false}
                  animate={{
                    boxShadow: searchQuery ? '0 0 0 2px #2A9D8F' : 'none',
                  }}
                  type="text"
                  placeholder="Search documents..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full pl-11 pr-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-[#2A9D8F] focus:border-transparent transition-shadow"
                />
                {searchQuery && (
                  <motion.button
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="absolute right-3 top-1/2 -translate-y-1/2"
                    onClick={() => setSearchQuery('')}
                  >
                    <XMarkIcon className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                  </motion.button>
                )}
              </div>
            </div>

            {/* Filter Buttons */}
            {Object.entries(filterOptions).map(
              ([filterKey, options]) =>
                options && ( // Only render if options exist for this filter
                  <div key={filterKey} className="relative inline-block dropdown-container">
                    <motion.button
                      ref={filterRefs[filterKey]}
                      whileHover={{ backgroundColor: 'rgba(42, 157, 143, 0.1)' }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => setActiveDropdown(activeDropdown === filterKey ? null : filterKey)}
                      className={`px-4 py-2.5 rounded-lg text-sm font-medium flex items-center space-x-2 focus:outline-none ${
                        filters[filterKey] !== 'all'
                          ? 'bg-[#2A9D8F]/10 text-[#2A9D8F] border border-[#2A9D8F]/20'
                          : 'bg-gray-50 text-gray-700 border border-gray-200 hover:border-[#2A9D8F]/20'
                      }`}
                    >
                      <span>{filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}</span>
                      <ChevronDownIcon className={`w-4 h-4 transition-transform ${activeDropdown === filterKey ? 'transform rotate-180' : ''}`} />
                    </motion.button>

                    <DropdownMenu isOpen={activeDropdown === filterKey} onClose={() => setActiveDropdown(null)} triggerRef={filterRefs[filterKey]}>
                      {options.map((option) => (
                        <motion.button
                          key={option.value}
                          whileHover={{ backgroundColor: 'rgba(42, 157, 143, 0.1)' }}
                          onClick={() => {
                            setFilters((prev) => ({ ...prev, [filterKey]: option.value }));
                            setActiveDropdown(null);
                          }}
                          className={`block w-full text-left px-4 py-2 text-sm ${
                            filters[filterKey] === option.value ? 'text-[#2A9D8F] bg-[#2A9D8F]/10' : 'text-gray-700 hover:text-[#2A9D8F]'
                          }`}
                        >
                          {option.label}
                        </motion.button>
                      ))}
                    </DropdownMenu>
                  </div>
                )
            )}
          </div>
        </div>

        <div className="border-t border-gray-200">
          <div className="overflow-x-auto relative">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-white border-b border-gray-100">
                  {columns.map((column) => (
                    <th
                      key={column.key}
                      scope="col"
                      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-gray-700 ${
                        column.key === 'actions' ? 'text-right' : ''
                      }`}
                      onClick={() =>
                        column.key !== 'actions' &&
                        setSortConfig((current) => ({
                          key: column.key,
                          direction: current.key === column.key && current.direction === 'asc' ? 'desc' : 'asc',
                        }))
                      }
                    >
                      <div className={`flex items-center space-x-1 ${column.key === 'actions' ? 'justify-end' : ''}`}>
                        <span>{column.label}</span>
                        {column.key !== 'actions' && (
                          <ChevronUpIcon
                            className={`w-4 h-4 transition-transform ${
                              sortConfig.key === column.key ? (sortConfig.direction === 'asc' ? 'transform rotate-0' : 'transform rotate-180') : 'text-gray-400'
                            }`}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {currentDocuments.map((doc) => (
                  <tr key={doc._id} className="hover:bg-gray-50 relative" onClick={(e) => handleRowClick(doc, e)}>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <div className="w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center">
                            <DocumentIcon document={doc} />
                          </div>
                        </div>
                        <div className="ml-4">
                          <div className="flex items-center space-x-2">
                            <div className="text-sm font-medium text-gray-900">{doc.name}</div>
                            <div className="text-sm text-gray-500">{doc?.version ? `${doc.version}` : ''}</div>
                          </div>
                          {renderStatusBadges(doc)}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{doc?.category || 'No folder'}</div>
                      <div className="text-xs text-gray-500">{doc?.subCategory || 'No subfolder'}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {doc.notes?.length || 0} {doc.notes?.length === 1 ? 'note' : 'notes'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{formatDate(doc.uploadedDate)}</div>
                      <div className="text-xs text-gray-500">{doc.uploadedBy?.email || 'Unknown'}</div>
                    </td>
                    <ActionCell
                      document={doc}
                      onArchive={handleArchive}
                      onUnarchive={handleUnarchive}
                      onDelete={handleDelete}
                      onVisibilityToggle={handleVisibilityToggle}
                      onImportanceToggle={handleImportanceToggle}
                      onSendApprovalReminder={handleSendApprovalReminder}
                      isLoading={loadingStates[doc._id]}
                      isOpen={activeActionDropdown === doc._id}
                      onToggle={() => setActiveActionDropdown(activeActionDropdown === doc._id ? null : doc._id)}
                      userRole={userRole}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex flex-1 justify-between sm:hidden">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setPage(page + 1)}
              disabled={page === totalPages}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </motion.button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{startIndex + 1}</span> to{' '}
                <span className="font-medium">{Math.min(endIndex, filteredAndSortedDocuments.length)}</span> of{' '}
                <span className="font-medium">{filteredAndSortedDocuments.length}</span> results
              </p>
            </div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
              >
                <ChevronLeftIcon className="h-5 w-5" />
              </motion.button>
              {[...Array(totalPages)].map((_, index) => (
                <motion.button
                  key={index + 1}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setPage(index + 1)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    page === index + 1
                      ? 'z-10 bg-[#2A9D8F] text-white focus-visible:outline-[#2A9D8F]'
                      : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                  }`}
                >
                  {index + 1}
                </motion.button>
              ))}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setPage(page + 1)}
                disabled={page === totalPages}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50"
              >
                <ChevronRightIcon className="h-5 w-5" />
              </motion.button>
            </nav>
          </div>
        </div>

        {filteredAndSortedDocuments.length === 0 && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="flex flex-col items-center justify-center py-12 bg-gray-50">
            <div className="text-center">
              <ClipboardDocumentListIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No documents found</h3>
              <p className="mt-1 text-sm text-gray-500">{searchQuery ? 'Try adjusting your search or filters' : 'Upload a document to get started'}</p>
            </div>
          </motion.div>
        )}
      </motion.div>

      <DocumentViewerModal
        document={selectedDocument}
        isOpen={isViewerOpen}
        onClose={() => {
          setIsViewerOpen(false);
          setSelectedDocument(null);
        }}
        onDocumentUpdate={onDocumentUpdate}
      />
    </>
  );
};

export default DocumentTable;
