import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { formatDistanceToNow } from 'date-fns';
import DocumentIcon from './DocumentIcon';

const RecentDocuments = ({ documents, onDocumentSelect }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const docsPerPage = 5;

  // Get recent documents sorted by upload date
  const recentDocs = documents.sort((a, b) => new Date(b.uploadedDate) - new Date(a.uploadedDate)).slice(0, 10);

  if (recentDocs.length === 0) return null;

  // Calculate pagination
  const totalPages = Math.ceil(recentDocs.length / docsPerPage);
  const startIndex = (currentPage - 1) * docsPerPage;
  const endIndex = startIndex + docsPerPage;
  const currentDocs = recentDocs.slice(startIndex, endIndex);

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-medium text-gray-900">Recently Added Documents</h2>
        {totalPages > 1 && (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="p-1 rounded-md text-gray-500 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <span className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
              className="p-1 rounded-md text-gray-500 hover:text-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {currentDocs.map((doc) => (
          <motion.button
            key={doc._id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            onClick={() => onDocumentSelect(doc)}
            className="group p-4 bg-white rounded-xl border border-gray-200 hover:border-[#2A9D8F] hover:shadow-lg transition-all duration-200 text-left relative"
          >
            {doc.isImportant && (
              <div className="absolute top-2 right-2 flex items-center">
                <StarIconSolid className="h-4 w-4 text-yellow-400 mr-1" />
                <span className="text-xs text-yellow-600">Important</span>
              </div>
            )}
            <div className="flex items-center justify-center mb-3">
              <div className="w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center group-hover:bg-[#2A9D8F]/10">
                <DocumentIcon document={doc} />
              </div>
            </div>
            <div className="space-y-1">
              <h3 className="text-sm font-medium text-gray-900 truncate" title={doc.name}>
                {doc.name}
              </h3>
              <p className="text-xs text-gray-500">{formatDistanceToNow(new Date(doc.uploadedDate), { addSuffix: true })}</p>
            </div>
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default RecentDocuments;
