import React from 'react';
import { LinkIcon, DocumentTextIcon, PhotoIcon, DocumentIcon as DocumentHeroIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const DocumentIcon = ({ document }) => {
  const getFileIcon = () => {
    if (document.url) {
      return <LinkIcon className="h-8 w-8 text-blue-500" />;
    }

    const key = document.key?.toLowerCase() || '';
    if (key.endsWith('.pdf')) {
      return <DocumentTextIcon className="h-8 w-8 text-red-500" />;
    } else if (key.match(/\.(jpg|jpeg|png|gif)$/)) {
      return <PhotoIcon className="h-8 w-8 text-green-500" />;
    } else if (key.match(/\.(doc|docx)$/)) {
      return <DocumentHeroIcon className="h-8 w-8 text-blue-500" />;
    }
    return <DocumentHeroIcon className="h-8 w-8 text-gray-500" />;
  };

  return (
    <div className="relative">
      {getFileIcon()}
      {document.approvedByClient ? (
        <CheckCircleIcon className="absolute -top-1 -right-1 h-5 w-5 text-green-500 bg-white rounded-full" />
      ) : (
        <div className="absolute -top-1 -right-1 h-5 w-5 bg-yellow-400 rounded-full flex items-center justify-center">
          {' '}
          <span className="text-[10px] font-bold text-yellow-800">AR</span>
        </div>
      )}
    </div>
  );
};

export default DocumentIcon;
