import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { setAuthToken } from './utils/api';
import LoginPage from './components/LoginPage';
import LandingPage from './components/LandingPage';
import LoginPageWithDescription from './components/LoginPageWithDescription';
import MainDashboardPage from './components/MainDashboardPage';
import SelectOrganisationTypePage from './components/Auth/SelectOrganisationTypePage';
import WithAuthentication from './components/Auth/WithAuthentication';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const UserContext = React.createContext();

export default function App() {
  const [userContextData, setUserContextData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'openid profile email',
          });

          setAuthToken(token);
          localStorage.setItem('auth_token', token);
        } catch (e) {
          console.error('Error getting token:', e);
          loginWithRedirect();
        }
      }
      setIsLoading(false);
    };

    getToken();
  }, [getAccessTokenSilently, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <UserContext.Provider value={{ userContextData, setUserContextData }}>
      <Router>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/private-landing-test" element={<LandingPage />} />
          <Route path="/login" element={<LoginPageWithDescription />} />

          {/* Protected Routes */}
          <Route path="/select-organisation-type" element={<SelectOrganisationTypePage />} />
          <Route
            path="/dashboard/*"
            element={
              <WithAuthentication>
                <MainDashboardPage />
              </WithAuthentication>
            }
          />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}
