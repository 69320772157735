import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Spinner } from 'flowbite-react';
import { UserContext } from '../../../App';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

import useGetProject from '../../Hooks/useGetProject';
import useGetProjectDocuments from '../../Hooks/useGetProjectDocuments';
import useGetTasks from '../../Hooks/useGetTasks';
import useGetProjectContacts from '../../Hooks/useGetProjectContacts';
import useGetUserRole from '../../Hooks/useGetUserRole';

import { DocumentFolders } from '../../Projects/Attachments';
import { MilestonesTimeline } from '../../Projects/Milestones';
import NotesSummaryV2 from '../../Projects/Notes/NotesSummaryV2';
import Divider from '../../UI/Divider';
import api from '../../../utils/api';
import {
  DocumentTextIcon,
  ChatBubbleLeftEllipsisIcon,
  ClockIcon,
  PhotoIcon,
  UserGroupIcon,
  InformationCircleIcon,
  ArrowPathIcon,
  PencilSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import { ChevronRightIcon as ChevronRightSolidIcon } from '@heroicons/react/20/solid';
import ProjectFactSheet from '../../Projects/Summary/ProjectFactSheet';

function ProjectSummaryPage() {
  const { projectId } = useParams();
  const { userContextData } = useContext(UserContext);
  const [activeSection, setActiveSection] = useState('details');
  const navigate = useNavigate();

  const [notes, setNotes] = useState([]);
  const [images, setImages] = useState([]);

  const [copiedItem, setCopiedItem] = useState({ table: null, index: null });
  const [organisation, setOrganisation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [milestones, setMilestones] = useState([]);
  const [recentActions, setRecentActions] = useState([]);
  const [actionsPage, setActionsPage] = useState(1);
  const [totalActionPages, setTotalActionPages] = useState(1);

  const [notesLoading, setNotesLoading] = useState(false);

  const { contacts, loading: contactsLoading, error: contactsError } = useGetProjectContacts(projectId);
  const { project, loading: projectLoading, error: projectError } = useGetProject(projectId);
  const { documents, loading: documentsLoading, error: documentsError } = useGetProjectDocuments(projectId);
  const { loading: tasksLoading, error: tasksError } = useGetTasks(projectId);
  const { role: userRole, loading: userRoleLoading, error: userRoleError } = useGetUserRole(projectId, userContextData.user_id);

  const loading = projectLoading || documentsLoading || tasksLoading || contactsLoading || userRoleLoading;
  const error = projectError || documentsError || tasksError || contactsError;

  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Not set';
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [imagesResponse, notesResponse, organisationResponse, milestonesResponse, recentActionsResponse] = await Promise.all([
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/images/project/${projectId}/images`),
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/notes/project/${projectId}/notes`),
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/organisations/${userContextData.organisation}`),
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/milestones/project/${projectId}/milestones`),
          api.get(`${process.env.REACT_APP_SERVER_URL}/api/projects/projects/${projectId}/actions?page=${actionsPage}&limit=5`),
        ]);

        setImages(imagesResponse.data);
        setNotes(notesResponse.data);
        setOrganisation(organisationResponse.data);
        setMilestones(milestonesResponse.data);
        setRecentActions(recentActionsResponse.data.actions);
        setTotalActionPages(recentActionsResponse.data.totalPages);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectId, userContextData.organisation, actionsPage]);

  // Separate useEffect to only refetch notes when switching to the Notes section
  useEffect(() => {
    if (activeSection === 'notes') {
      const fetchNotes = async () => {
        setNotesLoading(true);
        try {
          const notesResponse = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/notes/project/${projectId}/notes`);
          setNotes(notesResponse.data);
        } catch (error) {
          console.error('Failed to fetch notes:', error);
        } finally {
          setNotesLoading(false);
        }
      };

      fetchNotes();
    }
  }, [activeSection, projectId]);

  const handleCopyToClipboard = (email, index, tableTitle) => {
    navigator.clipboard.writeText(email).then(() => {
      setCopiedItem({ table: tableTitle, index });
      setTimeout(() => setCopiedItem({ table: null, index: null }), 2000);
    });
  };

  const getSectionFromField = (field) => {
    switch (field) {
      case 'Notes':
        return 'notes';
      case 'Documents':
        return 'attachments';
      default:
        return 'summary';
    }
  };

  const handleActionClick = (action) => {
    const section = getSectionFromField(action.field);

    if (projectId && section) {
      navigate(`/dashboard/project/${section}/${projectId}`);
    } else {
      console.error('No project ID found for this action');
    }
  };

  if (loading || isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner aria-label="Loading" />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center mt-4">Error: {error.message}</div>;
  }

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 },
    },
  };

  const InfoItem = ({ label, value, className = '' }) => (
    <div className={`px-6 py-4 transition-all duration-300 ${className}`}>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <dt className="text-sm font-medium text-gray-500">{label}</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{value || 'Not provided'}</dd>
      </div>
    </div>
  );

  const renderInformationTable = (title, data, columns) => {
    return (
      <div className="mt-8 bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
        </div>
        <div className="border-t border-gray-200">
          {data && data.length > 0 ? (
            <dl className="divide-y divide-gray-200">
              {data.map((item, index) => (
                <div key={item?._id || `row-${index}`} className="px-4 py-4 sm:px-6">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    {columns.map((column, colIndex) => (
                      <div key={`col-${colIndex}`}>
                        <dt className="text-sm font-medium text-gray-500">{column}</dt>
                        <dd className="mt-1 text-sm text-gray-900 flex items-center">
                          {item ? (
                            <>
                              {column === 'Phone' ? (
                                item.phone || 'N/A'
                              ) : column === 'Email' ? (
                                <>
                                  {item.email || 'N/A'}
                                  {item.email && (
                                    <button
                                      onClick={() => handleCopyToClipboard(item.email, index, title)}
                                      className="ml-2 text-gray-400 hover:text-[#2A9D8F] transition-colors duration-200"
                                      title={copiedItem.table === title && copiedItem.index === index ? 'Copied!' : 'Copy email'}
                                    >
                                      {copiedItem.table === title && copiedItem.index === index ? (
                                        <CheckIcon className="h-4 w-4" />
                                      ) : (
                                        <ClipboardDocumentIcon className="h-4 w-4" />
                                      )}
                                    </button>
                                  )}
                                </>
                              ) : (
                                item[column.toLowerCase()] || 'N/A'
                              )}
                            </>
                          ) : (
                            'N/A'
                          )}
                        </dd>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </dl>
          ) : (
            <p className="px-4 py-5 sm:px-6 text-sm text-gray-500 italic">No {title.toLowerCase()} available</p>
          )}
        </div>
      </div>
    );
  };

  const renderProjectImages = () => {
    const selectedImages = images.filter((image) => image.selected);
    if (selectedImages.length === 0) return null;

    return (
      <div className="w-48 h-48 overflow-hidden rounded-md shadow-md relative">
        <img src={selectedImages[0].signedUrl} alt="Project Image" className="w-full h-full object-cover" />
        {selectedImages.length > 1 && (
          <div className="absolute bottom-1 right-1 bg-white rounded-full px-2 py-1 text-sm font-semibold text-gray-700">+{selectedImages.length - 1}</div>
        )}
      </div>
    );
  };

  const getActionIcon = (action) => {
    switch (action.field) {
      case 'Notes':
        return <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      case 'Documents':
        return <DocumentTextIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      case 'Images':
        return <PhotoIcon className="h-5 w-5 text-white" aria-hidden="true" />;
      default:
        return <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />;
    }
  };

  const getActionBackground = (action) => {
    switch (action.field) {
      case 'Notes':
        return 'bg-yellow-500';
      case 'Documents':
        return 'bg-blue-500';
      case 'Images':
        return 'bg-pink-500';
      default:
        return 'bg-green-500';
    }
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const countVisibleDocuments = (documents) => {
    if (!Array.isArray(documents)) return 0;

    const visibleDocs = userRole === 'admin' ? documents : documents.filter((doc) => doc.visibleToClient);

    return visibleDocs.length;
  };

  const navigationItems = [
    {
      id: 'details',
      name: 'Details',
      icon: <InformationCircleIcon className="h-6 w-6" />,
      count: null,
      gradientClass: 'from-blue-500 to-blue-600',
    },
    {
      id: 'milestones',
      name: 'Milestones',
      icon: <ClockIcon className="h-6 w-6" />,
      count: milestones.length,
      color: 'bg-purple-500',
    },
    {
      id: 'documents',
      name: 'Documents',
      icon: <DocumentTextIcon className="h-6 w-6" />,
      count: countVisibleDocuments(documents),
      color: 'bg-pink-500',
    },
    {
      id: 'notes',
      name: 'Notes',
      icon: <ChatBubbleLeftEllipsisIcon className="h-6 w-6" />,
      count: notes.length,
      color: 'bg-green-500',
    },
    {
      id: 'fact-sheet',
      name: 'Fact Sheet',
      icon: <ClipboardDocumentListIcon className="h-6 w-6" />,
      count: null,
      color: 'bg-indigo-500',
    },
  ];

  return (
    <motion.div initial="hidden" animate="visible" variants={containerVariants} className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 min-h-screen">
      {/* Mobile Navigation */}
      <div className="md:hidden">
        <motion.div className="grid grid-cols-3 gap-4 mt-4" variants={containerVariants}>
          {navigationItems.slice(0, 3).map((item) => (
            <motion.button
              key={item.id}
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveSection(item.id)}
              className={`flex flex-col items-center p-4 rounded-xl 
                         ${activeSection === item.id ? 'bg-white/90 shadow-lg' : 'bg-white/70'} 
                         backdrop-blur-lg border 
                         shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]
                         hover:bg-white/90 transition-all duration-300`}
            >
              <div
                className={`p-3 rounded-full 
                              ${
                                activeSection === item.id
                                  ? item.id === 'details'
                                    ? 'bg-gradient-to-r from-blue-500 to-blue-600'
                                    : item.id === 'milestones'
                                    ? 'bg-gradient-to-r from-purple-500 to-purple-600'
                                    : item.id === 'documents'
                                    ? 'bg-gradient-to-r from-pink-500 to-pink-600'
                                    : 'bg-gradient-to-r from-[#2A9D8F] to-[#258B7A]'
                                  : 'bg-gray-100'
                              }`}
              >
                <div className={activeSection === item.id ? 'text-white' : 'text-gray-500'}>{item.icon}</div>
              </div>
              <span
                className={`text-sm font-medium mt-2
                               ${
                                 activeSection === item.id
                                   ? item.id === 'details'
                                     ? 'text-blue-600'
                                     : item.id === 'milestones'
                                     ? 'text-purple-600'
                                     : item.id === 'documents'
                                     ? 'text-pink-600'
                                     : 'text-[#2A9D8F]'
                                   : 'text-gray-900'
                               }`}
              >
                {item.name}
              </span>
              {item.count !== null && (
                <span
                  className={`text-xs mt-1
                                 ${
                                   activeSection === item.id
                                     ? item.id === 'details'
                                       ? 'text-blue-600'
                                       : item.id === 'milestones'
                                       ? 'text-purple-600'
                                       : item.id === 'documents'
                                       ? 'text-pink-600'
                                       : 'text-[#2A9D8F]'
                                     : 'text-gray-500'
                                 }`}
                >
                  {item.count}
                </span>
              )}
            </motion.button>
          ))}
        </motion.div>
        <motion.div className="grid grid-cols-2 gap-4 mt-4" variants={containerVariants}>
          {navigationItems.slice(3).map((item) => (
            <motion.button
              key={item.id}
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveSection(item.id)}
              className={`flex flex-col items-center p-4 rounded-xl 
                         ${activeSection === item.id ? 'bg-white/90 shadow-lg' : 'bg-white/70'} 
                         backdrop-blur-lg border 
                         shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]
                         hover:bg-white/90 transition-all duration-300`}
            >
              <div
                className={`p-3 rounded-full 
                              ${
                                activeSection === item.id
                                  ? item.id === 'notes'
                                    ? 'bg-gradient-to-r from-green-500 to-green-600'
                                    : item.id === 'fact-sheet'
                                    ? 'bg-gradient-to-r from-indigo-500 to-indigo-600'
                                    : 'bg-gradient-to-r from-[#2A9D8F] to-[#258B7A]'
                                  : 'bg-gray-100'
                              }`}
              >
                <div className={activeSection === item.id ? 'text-white' : 'text-gray-500'}>{item.icon}</div>
              </div>
              <span
                className={`text-sm font-medium mt-2
                               ${
                                 activeSection === item.id
                                   ? item.id === 'notes'
                                     ? 'text-green-600'
                                     : item.id === 'fact-sheet'
                                     ? 'text-indigo-600'
                                     : 'text-[#2A9D8F]'
                                   : 'text-gray-900'
                               }`}
              >
                {item.name}
              </span>
              {item.count !== null && (
                <span
                  className={`text-xs mt-1
                                 ${
                                   activeSection === item.id
                                     ? item.id === 'notes'
                                       ? 'text-green-600'
                                       : item.id === 'fact-sheet'
                                       ? 'text-indigo-600'
                                       : 'text-[#2A9D8F]'
                                     : 'text-gray-500'
                                 }`}
                >
                  {item.count}
                </span>
              )}
            </motion.button>
          ))}
        </motion.div>
      </div>

      {/* Desktop Navigation */}
      <motion.nav className="hidden md:flex overflow-x-auto py-6" variants={containerVariants}>
        <ul className="flex min-w-full flex-none gap-x-6 text-sm font-semibold">
          {navigationItems.map((item) => (
            <motion.li key={item.id} variants={itemVariants} whileHover={{ scale: 1.05 }} className="relative">
              <button
                onClick={() => setActiveSection(item.id)}
                className={`
                  px-6 py-3 rounded-full transition-all duration-300
                  ${activeSection === item.id ? 'bg-white/80 shadow-lg backdrop-blur-sm border border-white/20' : 'hover:bg-white/50'}
                  flex items-center gap-2
                `}
              >
                <span
                  className={`
                  ${activeSection === item.id ? 'bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] bg-clip-text text-transparent' : 'text-gray-600'}
                `}
                >
                  {item.name}
                </span>
                {item.count !== null && (
                  <span
                    className={`
                    px-2 py-0.5 text-xs rounded-full
                    ${activeSection === item.id ? 'bg-[#2A9D8F] text-white' : 'bg-gray-100 text-gray-600'}
                  `}
                  >
                    {item.count}
                  </span>
                )}
              </button>
            </motion.li>
          ))}
        </ul>
      </motion.nav>

      {/* Content Section */}
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          key={activeSection}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
          className="mt-8 space-y-10"
        >
          {activeSection === 'details' && (
            <motion.div variants={itemVariants} initial="hidden" animate="visible" className="space-y-8">
              {/* Project Details Card */}
              <div
                className="bg-white/80 backdrop-blur-lg rounded-2xl overflow-hidden
                             border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]"
              >
                <div className="px-6 py-5">
                  <div className="flex flex-col sm:flex-row justify-between items-start">
                    <div className="flex-grow mb-4 sm:mb-0">
                      <h3
                        className="text-xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] 
                                   bg-clip-text text-transparent"
                      >
                        Project Details
                      </h3>
                      <p className="mt-2 text-gray-600">Key information about the project</p>
                    </div>
                    <div className="flex-shrink-0">{renderProjectImages()}</div>
                  </div>
                </div>

                <div className="border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <InfoItem label="Client Name" value={project?.clients[0]?.name} className="hover:bg-gray-50/50" />
                    <InfoItem label="Job Ref" value={project.jobReference} className="hover:bg-gray-50/50" />
                    <InfoItem label="Manager" value={project.projectManager} className="hover:bg-gray-50/50" />
                    <InfoItem label="Property Address" value={project.propertyAddress} className="hover:bg-gray-50/50" />
                    <InfoItem label="Start Date" value={formatDate(project.projectStartDate)} className="hover:bg-gray-50/50" />
                    <InfoItem label="End Date" value={formatDate(project.projectEndDate)} className="hover:bg-gray-50/50" />
                  </dl>
                </div>
              </div>

              {/* Client Information Card */}
              {renderInformationTable('Client Information', project?.clients, ['Name', 'Email', 'Phone'])}

              {/* Organization Information Card */}
              {organisation && renderInformationTable('Organization Information', [organisation], ['Name', 'Email', 'Number'])}

              {/* Contacts Information Card */}
              {contacts && contacts.length > 0 && renderInformationTable('Project Contacts', contacts, ['Name', 'Email', 'Phone'])}

              {/* Recent Actions */}
              <div
                id="recent-actions"
                className="bg-white/80 backdrop-blur-lg rounded-2xl overflow-hidden
                                        border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]"
              >
                <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                  <h3
                    className="text-xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] 
                                bg-clip-text text-transparent"
                  >
                    Recent Actions
                  </h3>
                  <span className="text-sm text-gray-500">
                    Page {actionsPage} of {totalActionPages}
                  </span>
                </div>
                <div className="border-t border-gray-200">
                  {recentActions.length > 0 ? (
                    <ul role="list" className="divide-y divide-gray-200">
                      {recentActions.map((action) => (
                        <li
                          key={action._id}
                          className="px-4 py-4 sm:px-6 hover:bg-gray-50/50 transition duration-150 cursor-pointer"
                          onClick={() => handleActionClick(action)}
                        >
                          <div className="flex items-center space-x-4">
                            <div className={`flex-shrink-0 h-10 w-10 rounded-full ${getActionBackground(action)} flex items-center justify-center`}>
                              {getActionIcon(action)}
                            </div>
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {action.user} {action.action} {action.field}
                              </p>
                              {action.itemName && <p className="text-sm text-gray-500 truncate">{action.itemName}</p>}
                            </div>
                            <div className="flex-shrink-0 text-sm text-gray-500">{formatTimestamp(action.timestamp)}</div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="px-4 py-5 sm:px-6 text-sm text-gray-500 italic">No recent actions available</p>
                  )}
                </div>
                <div className="flex justify-between items-center px-4 py-3 border-t border-gray-200 bg-gray-50/50">
                  <button
                    onClick={() => setActionsPage(actionsPage - 1)}
                    disabled={actionsPage === 1}
                    className="inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] disabled:opacity-50"
                  >
                    <ChevronLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />
                    Previous
                  </button>
                  <button
                    onClick={() => setActionsPage(actionsPage + 1)}
                    disabled={actionsPage === totalActionPages}
                    className="inline-flex items-center px-3 py-1 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F] disabled:opacity-50"
                  >
                    Next
                    <ChevronRightIcon className="h-5 w-5 ml-1" aria-hidden="true" />
                  </button>
                </div>
              </div>

              {/* Project Contacts Section */}
              <div
                id="contacts"
                className="bg-white/80 backdrop-blur-lg rounded-2xl overflow-hidden
                                   border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]"
              >
                <div className="px-4 py-5 sm:px-6">
                  <h3
                    className="text-xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] 
                                bg-clip-text text-transparent"
                  >
                    Project Contacts
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Key contacts for this project.</p>
                </div>
                <div className="border-t border-gray-200">
                  {contacts && contacts.length > 0 ? (
                    <dl className="divide-y divide-gray-200">
                      {contacts.map((contact, index) => (
                        <div key={contact._id} className="px-4 py-4 sm:px-6">
                          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                            <div>
                              <dt className="text-sm font-medium text-gray-500">Name</dt>
                              <dd className="mt-1 text-sm text-gray-900">{contact.name}</dd>
                            </div>
                            <div>
                              <dt className="text-sm font-medium text-gray-500">Email</dt>
                              <dd className="mt-1 text-sm text-gray-900">{contact.email}</dd>
                            </div>
                            <div>
                              <dt className="text-sm font-medium text-gray-500">Phone</dt>
                              <dd className="mt-1 text-sm text-gray-900">{contact.phone}</dd>
                            </div>
                          </div>
                        </div>
                      ))}
                    </dl>
                  ) : (
                    <p className="px-4 py-5 sm:px-6 text-sm text-gray-500 italic">No contacts available</p>
                  )}
                </div>
                <div className="px-4 py-5 sm:px-6">
                  <Link to={`/dashboard/project/contacts/${projectId}`}>
                    <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#2A9D8F] hover:bg-[#258B7A] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2A9D8F]">
                      Manage Contacts
                    </button>
                  </Link>
                </div>
              </div>
            </motion.div>
          )}

          {activeSection === 'milestones' && (
            <motion.div variants={itemVariants} initial="hidden" animate="visible">
              <MilestonesTimeline initialMilestones={milestones} />
            </motion.div>
          )}

          {activeSection === 'documents' && (
            <motion.div variants={itemVariants} initial="hidden" animate="visible">
              <DocumentFolders documents={userRole === 'admin' ? documents : documents.filter((doc) => doc.visibleToClient)} userRole={userRole} />
            </motion.div>
          )}

          {activeSection === 'notes' && (
            <motion.div variants={itemVariants} initial="hidden" animate="visible" className="bg-white shadow-lg rounded-lg overflow-hidden">
              {notesLoading ? (
                <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-12">
                  <div className="flex flex-col items-center justify-center">
                    <div className="animate-spin rounded-full h-10 w-10 border-4 border-gray-200 border-t-[#2A9D8F]" />
                    <p className="mt-4 text-sm text-gray-600 font-medium">Loading project notes...</p>
                  </div>
                </div>
              ) : (
                <NotesSummaryV2
                  notes={notes}
                  refetch={async () => {
                    setNotesLoading(true);
                    try {
                      const notesResponse = await api.get(`${process.env.REACT_APP_SERVER_URL}/api/notes/project/${projectId}/notes`);
                      setNotes(notesResponse.data);
                    } catch (error) {
                      console.error('Failed to fetch notes:', error);
                    } finally {
                      setNotesLoading(false);
                    }
                  }}
                />
              )}
            </motion.div>
          )}

          {activeSection === 'fact-sheet' && (
            <motion.div variants={itemVariants} initial="hidden" animate="visible" className="bg-white shadow-lg rounded-lg overflow-hidden">
              <ProjectFactSheet project={project} />
            </motion.div>
          )}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
}

export default ProjectSummaryPage;
