import React, { useState, useEffect, useRef, forwardRef, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  FolderIcon,
  DocumentIcon as DocumentHeroIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  PlusIcon,
  FunnelIcon,
  EllipsisHorizontalIcon,
  StarIcon,
  ArchiveBoxIcon,
  TrashIcon,
  PencilIcon,
  ShareIcon,
  DocumentDuplicateIcon,
  CloudArrowDownIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ViewColumnsIcon,
  Squares2X2Icon,
  PhotoIcon,
  LinkIcon,
  XMarkIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  CalendarIcon,
  ChevronLeftIcon,
  CheckIcon,
  ArrowPathIcon,
  CloudArrowUpIcon,
  PlayIcon,
  CursorArrowRaysIcon,
  EyeIcon,
  BellIcon,
} from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { formatDistanceToNow } from 'date-fns';
import { Menu } from '@headlessui/react';
import { documentService } from '../../../services/documentService';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { noteService } from '../../../services/noteService';
import { motion } from 'framer-motion';
import DocumentViewerModal from './DocumentViewerModal';
import DocumentTable from './DocumentTable';
import DocumentIcon from './DocumentIcon';
import RecentDocuments from './RecentDocuments';
import useGetUserRole from '../../Hooks/useGetUserRole';

import { useContext } from 'react';
import { UserContext } from '../../../App';

// Create ref-forwarding components for Transition
const TransitionChild = forwardRef((props, ref) => <Transition.Child ref={ref} {...props} />);

const DialogPanelWithRef = forwardRef((props, ref) => <Dialog.Panel ref={ref} {...props} />);

// Wrap UploadForm with forwardRef
const UploadForm = forwardRef(({ category, projectId, userRole, onClose, currentPath, onUploadComplete, existingSubfolders = [] }, ref) => {
  const [docName, setDocName] = useState('');
  const [isFile, setIsFile] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [docUrl, setDocUrl] = useState('');
  const [docVersion, setDocVersion] = useState('');
  const [isImportant, setIsImportant] = useState(false);
  const [visibleToClient, setVisibleToClient] = useState(true);
  const [sendEmail, setSendEmail] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const { userContextData } = useContext(UserContext);

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!selectedFile && !docUrl) return;

    setUploading(true);
    const formData = new FormData();
    formData.append('name', docName);

    // Handle category and subcategory based on currentPath
    if (currentPath.length > 0) {
      formData.append('category', currentPath[0]); // First element is always the main category
      if (currentPath.length > 1) {
        // If we're in a subfolder, use it as the subcategory
        formData.append('subCategory', currentPath[1]);
      }
    }

    formData.append('version', docVersion);
    formData.append('isImportant', isImportant);
    formData.append('visibleToClient', true);
    formData.append('sendEmail', sendEmail);

    if (isFile && selectedFile) {
      formData.append('file', selectedFile);
    } else if (!isFile && docUrl) {
      formData.append('url', docUrl);
    }

    try {
      await documentService.uploadDocument(projectId, formData);
      onUploadComplete();
      onClose();
    } catch (error) {
      console.error('Error uploading document:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
      // Only set the name if it's not already defined by the user
      if (!docName || docName === '') {
        setDocName(file.name);
      }
    }
  };

  const handleRemoveFile = (e) => {
    e.preventDefault(); // Prevent the file input from opening
    e.stopPropagation(); // Stop event bubbling
    setSelectedFile(null);
    setDocName(''); // Reset the document name when removing file
  };

  return (
    <Dialog.Panel ref={ref} className="w-full max-w-lg transform overflow-hidden rounded-3xl bg-white shadow-2xl transition-all">
      <div className="px-6 pt-6">
        <div className="flex items-start justify-between">
          <div className="text-left">
            <Dialog.Title as="h3" className="text-xl font-semibold text-gray-900">
              Upload Document
            </Dialog.Title>
            {currentPath.length > 0 && (
              <p className="mt-2 text-sm text-gray-500 flex items-center">
                <FolderIcon className="h-4 w-4 mr-1.5 text-gray-400" />
                {currentPath.join(' / ')}
              </p>
            )}
          </div>
          <button onClick={onClose} className="rounded-full p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 transition-colors">
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>
      </div>

      <form onSubmit={handleUpload} className="mt-6">
        <div className="space-y-6 px-6">
          {/* Type Selection - Updated with modern toggle style */}
          <div>
            <div className="flex rounded-2xl bg-gray-50 p-1">
              <button
                type="button"
                onClick={() => setIsFile(true)}
                className={`flex-1 rounded-xl px-4 py-3 text-sm font-medium transition-all duration-200 ${
                  isFile ? 'bg-white text-gray-900 shadow-sm ring-1 ring-gray-200' : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                <span className="flex items-center justify-center">
                  <DocumentTextIcon className="h-4 w-4 mr-2" />
                  File Upload
                </span>
              </button>
              <button
                type="button"
                onClick={() => setIsFile(false)}
                className={`flex-1 rounded-xl px-4 py-3 text-sm font-medium transition-all duration-200 ${
                  !isFile ? 'bg-white text-gray-900 shadow-sm ring-1 ring-gray-200' : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                <span className="flex items-center justify-center">
                  <LinkIcon className="h-4 w-4 mr-2" />
                  URL Link
                </span>
              </button>
            </div>
          </div>

          {/* File Upload Area - Modified with remove button */}
          {isFile && (
            <div
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              className={`relative rounded-2xl border-2 border-dashed h-40 flex items-center justify-center transition-all duration-200
                ${dragActive ? 'border-[#2A9D8F] bg-[#2A9D8F]/5 ring-4 ring-[#2A9D8F]/10' : 'border-gray-200 hover:border-[#2A9D8F] hover:bg-gray-50'}`}
            >
              {!selectedFile && (
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    setSelectedFile(file || null);
                    if (file && (!docName || docName === '')) {
                      setDocName(file.name);
                    }
                  }}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
              )}
              <div className="text-center">
                <div className="mx-auto h-12 w-12 mb-3 rounded-xl bg-gray-50 flex items-center justify-center">
                  {selectedFile ? <DocumentTextIcon className="h-6 w-6 text-[#2A9D8F]" /> : <CloudArrowDownIcon className="h-6 w-6 text-[#2A9D8F]" />}
                </div>
                <div className="text-sm text-gray-600">
                  {selectedFile ? (
                    <div className="flex flex-col items-center">
                      <span className="font-medium text-[#2A9D8F] mb-2">{selectedFile.name}</span>
                      <button
                        type="button"
                        onClick={handleRemoveFile}
                        className="text-xs px-3 py-1 bg-red-50 text-red-600 rounded-full hover:bg-red-100 transition-colors"
                      >
                        Remove file
                      </button>
                    </div>
                  ) : (
                    <span>
                      <span className="font-medium text-[#2A9D8F]">Click to upload</span>
                      <span className="text-gray-500"> or drag and drop</span>
                    </span>
                  )}
                  <p className="mt-1 text-xs text-gray-400">PDF, DOC, DOCX, XLS, XLSX up to 10MB</p>
                </div>
              </div>
            </div>
          )}

          {/* Document Details - Modern input styling */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">Document Name *</label>
              <input
                type="text"
                value={docName}
                onChange={(e) => setDocName(e.target.value)}
                className="block w-full rounded-xl border-gray-200 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm transition-colors"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">Version</label>
              <input
                type="text"
                value={docVersion}
                onChange={(e) => setDocVersion(e.target.value)}
                className="block w-full rounded-xl border-gray-200 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm transition-colors"
                placeholder="e.g. 1.0"
              />
            </div>

            {!isFile && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1.5">URL *</label>
                <input
                  type="url"
                  value={docUrl}
                  onChange={(e) => setDocUrl(e.target.value)}
                  className="block w-full rounded-xl border-gray-200 shadow-sm focus:border-[#2A9D8F] focus:ring-[#2A9D8F] sm:text-sm transition-colors"
                  required={!isFile}
                  placeholder="https://"
                />
              </div>
            )}
          </div>

          {/* Document Settings */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1.5">Document Settings</label>
              <div className="space-y-0.5 rounded-xl border border-gray-200 divide-y divide-gray-100 text-left">
                {[
                  {
                    state: visibleToClient,
                    setState: setVisibleToClient,
                    label: 'Visible to Client',
                    description: 'Allow client to view this document',
                    icon: EyeIcon,
                    show: userRole === 'admin',
                  },
                  {
                    state: isImportant,
                    setState: setIsImportant,
                    label: 'Mark as Important',
                    description: 'Highlight this document for the project team & clients',
                    icon: StarIcon,
                    show: true,
                  },
                  {
                    state: sendEmail,
                    setState: setSendEmail,
                    label: 'Send Notification',
                    description: 'Notify team members via email',
                    icon: BellIcon,
                    show: true,
                  },
                ].map(
                  (option, index) =>
                    option.show && (
                      <label key={index} className="flex items-center px-4 py-3 cursor-pointer hover:bg-gray-50 transition-colors duration-200">
                        <div className="flex-1 min-w-0 flex items-center">
                          <option.icon className="h-4 w-4 text-gray-400 mr-3" />
                          <div>
                            <div className="text-sm font-medium text-gray-900">{option.label}</div>
                            <div className="text-xs text-gray-500">{option.description}</div>
                          </div>
                        </div>
                        <div className="ml-4 flex items-center">
                          <input
                            type="checkbox"
                            checked={option.state}
                            onChange={(e) => option.setState(e.target.checked)}
                            className="h-4 w-4 rounded border-gray-300 text-[#2A9D8F] focus:ring-[#2A9D8F] focus:ring-offset-0"
                          />
                        </div>
                      </label>
                    )
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Footer - Modern button styling */}
        <div className="mt-6 flex items-center justify-end gap-3 bg-gray-50/80 px-6 py-4">
          <button type="button" onClick={onClose} className="rounded-xl px-4 py-2.5 text-sm font-medium text-gray-700 hover:bg-gray-100 transition-colors">
            Cancel
          </button>
          <button
            type="submit"
            disabled={uploading || (!selectedFile && !docUrl) || !docName}
            className="inline-flex items-center rounded-xl bg-[#2A9D8F] px-4 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-[#2A9D8F]/90 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200"
          >
            {uploading ? (
              <>
                <ArrowPathIcon className="mr-2 h-4 w-4 animate-spin" />
                Uploading...
              </>
            ) : (
              <>
                <CloudArrowUpIcon className="mr-2 h-4 w-4" />
                Upload
              </>
            )}
          </button>
        </div>
      </form>
    </Dialog.Panel>
  );
});

// Add display name for the UploadForm
UploadForm.displayName = 'UploadForm';

const EmptyState = ({ currentPath, setIsUploadModalOpen, setIsNewFolderModalOpen, isInSubCategory }) => (
  <div className="relative">
    {/* Background pattern */}
    <div className="absolute inset-0 grid grid-cols-6 gap-4 opacity-5 pointer-events-none">
      {[...Array(24)].map((_, i) => (
        <div key={i} className="h-32 bg-[#2A9D8F] rounded-lg transform rotate-3" />
      ))}
    </div>

    <div className="relative flex flex-col items-center justify-center py-20 px-4 text-center">
      <div className="mb-6 p-4 bg-[#2A9D8F]/10 rounded-full">
        <DocumentTextIcon className="h-12 w-12 text-[#2A9D8F]" />
      </div>

      <h3 className="text-xl font-semibold text-gray-900 mb-2">{currentPath.length === 0 ? 'No documents yet' : 'This folder is empty'}</h3>

      <p className="text-gray-500 max-w-md mb-8">
        {currentPath.length === 0
          ? 'Get started by uploading your first document or creating a folder to organize your files.'
          : `Start adding documents to ${currentPath[currentPath.length - 1]} to keep your project organized.`}
      </p>

      <div className="flex flex-col sm:flex-row gap-4">
        <button
          onClick={() => setIsUploadModalOpen(true)}
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-[#2A9D8F] rounded-lg hover:bg-[#2A9D8F]/90 transition-colors"
        >
          <DocumentHeroIcon className="h-5 w-5 mr-2" />
          Upload File
        </button>

        {!isInSubCategory() && (
          <button
            onClick={() => setIsNewFolderModalOpen(true)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-[#2A9D8F] bg-white border border-[#2A9D8F] rounded-lg hover:bg-[#2A9D8F] hover:text-white transition-colors"
          >
            <FolderIcon className="h-5 w-5 mr-2" />
            Create Folder
          </button>
        )}
      </div>
    </div>
  </div>
);

const DocumentFolders = ({ documents: initialDocuments, userRole }) => {
  const { projectId } = useParams();
  const [sortBy, setSortBy] = useState('uploadedDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'
  const [currentPath, setCurrentPath] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([{ name: 'Home', path: [] }]);
  const [currentView, setCurrentView] = useState('folders'); // 'folders' or 'documents'
  const [contextMenu, setContextMenu] = useState(null);

  // Add documents state
  const [documents, setDocuments] = useState(initialDocuments);
  const [documentStructure, setDocumentStructure] = useState([]);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  console.log(documents);

  // Add new state for filtering
  const [filterText, setFilterText] = useState('');

  // Add new state for document viewer
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  // Add default folders constant
  const DEFAULT_FOLDERS = [
    { id: 'working-documents', name: 'Working Documents', type: 'folder', children: [] },
    { id: 'onsite-documents', name: 'Onsite Documents', type: 'folder', children: [] },
    { id: 'formal-documents', name: 'Formal Documents', type: 'folder', children: [] },
    { id: 'reference-documents', name: 'Reference Documents', type: 'folder', children: [] },
  ];

  // Add validation
  useEffect(() => {
    if (!projectId) {
      console.error('ProjectId is required');
      return;
    }
  }, [projectId]);

  // Update useEffect to use local documents state
  useEffect(() => {
    const structure = organizeDocuments(documents);
    setDocumentStructure(structure);
  }, [documents]);

  // Update when initial documents change
  useEffect(() => {
    setDocuments(initialDocuments);
  }, [initialDocuments]);

  const organizeDocuments = (docs) => {
    if (!Array.isArray(docs)) return [];

    // Create a map of default folders
    const folderMap = new Map(DEFAULT_FOLDERS.map((folder) => [folder.name, { ...folder }]));

    // Create root Archived folder if there are archived documents without a category
    const hasRootArchivedDocs = docs.some((doc) => (doc.isArchived || doc.subCategory === 'Archived') && !doc.category);
    if (hasRootArchivedDocs) {
      folderMap.set('Archived', { id: 'archived', name: 'Archived', type: 'folder', children: [] });
    }

    // Process all documents
    docs.forEach((doc) => {
      const isDocArchived = doc.isArchived || doc.subCategory === 'Archived';

      // Handle root-level archived documents
      if (isDocArchived && !doc.category) {
        const archivedFolder = folderMap.get('Archived');
        if (archivedFolder) {
          archivedFolder.children.push({
            id: doc._id,
            name: doc.name,
            type: 'file',
            data: doc,
            isFolder: false,
          });
        }
        return; // Skip further processing for this document
      }

      // Process documents with categories
      if (doc.category) {
        let categoryFolder = folderMap.get(doc.category);

        // Create category folder if it doesn't exist
        if (!categoryFolder) {
          categoryFolder = {
            id: doc.category,
            name: doc.category,
            type: 'folder',
            children: [],
          };
          folderMap.set(doc.category, categoryFolder);
        }

        // Ensure children array exists
        if (!categoryFolder.children) {
          categoryFolder.children = [];
        }

        // Handle archived documents within categories
        if (isDocArchived) {
          let archivedSubfolder = categoryFolder.children.find((child) => child.name === 'Archived');
          if (!archivedSubfolder) {
            archivedSubfolder = {
              id: `${doc.category}/Archived`,
              name: 'Archived',
              type: 'folder',
              children: [],
            };
            categoryFolder.children.push(archivedSubfolder);
          }
          archivedSubfolder.children.push({
            id: doc._id,
            name: doc.name,
            type: 'file',
            data: doc,
            isFolder: false,
          });
        }
        // Handle non-archived documents with subcategories
        else if (doc.subCategory && doc.subCategory !== 'Archived') {
          let subFolder = categoryFolder.children.find((child) => child.name === doc.subCategory);
          if (!subFolder) {
            subFolder = {
              id: `${doc.category}/${doc.subCategory}`,
              name: doc.subCategory,
              type: 'folder',
              children: [],
            };
            categoryFolder.children.push(subFolder);
          }
          subFolder.children.push({
            id: doc._id,
            name: doc.name,
            type: 'file',
            data: doc,
            isFolder: false,
          });
        }
        // Handle documents without subcategories
        else {
          categoryFolder.children.push({
            id: doc._id,
            name: doc.name,
            type: 'file',
            data: doc,
            isFolder: false,
          });
        }
      }
    });

    // Get root-level files (documents without categories and not archived)
    const rootFiles = docs
      .filter((doc) => !doc.category && !doc.isArchived && doc.subCategory !== 'Archived')
      .map((doc) => ({
        id: doc._id,
        name: doc.name,
        type: 'file',
        data: doc,
        isFolder: false,
      }));

    return [...Array.from(folderMap.values()), ...rootFiles];
  };

  const getCurrentContent = () => {
    let content = documentStructure;

    // If we're at root level, return the root folders and files
    if (currentPath.length === 0) {
      return content.map((item) => ({
        ...item,
        isFolder: item.type === 'folder',
        name: item.name || item.data?.name, // Use data.name as fallback for files
        data: item.type === 'file' ? item.data : null,
      }));
    }

    // Navigate through the path to get current content
    let currentFolder = content.find((item) => item.name === currentPath[0]);

    for (let i = 1; i < currentPath.length; i++) {
      if (!currentFolder?.children) return [];
      currentFolder = currentFolder.children.find((item) => item.name === currentPath[i]);
    }

    if (!currentFolder?.children) return [];

    // Map the current folder's contents
    return currentFolder.children.map((item) => ({
      ...item,
      isFolder: item.type === 'folder',
      name: item.name || item.data?.name,
      data: item.type === 'file' ? item.data : null,
    }));
  };

  const handleCloseModal = () => {
    setIsNewFolderModalOpen(false);
    setNewFolderName(''); // Reset the name when closing
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;

    setIsCreatingFolder(true);
    try {
      // Create a new folder structure
      const newFolder = {
        id: currentPath.length > 0 ? `${currentPath[0]}/${newFolderName}` : newFolderName,
        name: newFolderName,
        type: 'folder',
        children: [],
      };

      // If we're inside a folder, add it as a subfolder
      if (currentPath.length > 0) {
        setDocumentStructure((prevStructure) => {
          const updatedStructure = [...prevStructure];
          const parentFolder = updatedStructure.find((item) => item.name === currentPath[0]);

          if (parentFolder) {
            if (!parentFolder.children) {
              parentFolder.children = [];
            }
            parentFolder.children.push({
              id: `${parentFolder.name}/${newFolderName}`,
              name: newFolderName,
              type: 'folder',
              children: [],
            });
          }
          return updatedStructure;
        });
      } else {
        // Add as root folder
        setDocumentStructure((prevStructure) => {
          const updatedStructure = Array.isArray(prevStructure) ? [...prevStructure] : [];
          updatedStructure.push(newFolder);
          return updatedStructure;
        });
      }

      // Close modal and reset form
      handleCloseModal();
      toast.success('Folder created successfully');
    } catch (error) {
      console.error('Error creating folder:', error);
      toast.error('Failed to create folder');
    } finally {
      setIsCreatingFolder(false);
    }
  };

  const handleFolderClick = (folderName) => {
    const newPath = [...currentPath, folderName];
    setCurrentPath(newPath);

    // Update breadcrumbs
    const newBreadcrumbs = [
      { name: 'Home', path: [] },
      ...newPath.map((name, index) => ({
        name,
        path: newPath.slice(0, index + 1),
      })),
    ];
    setBreadcrumbs(newBreadcrumbs);
  };

  const handleBreadcrumbClick = (path) => {
    setCurrentPath(path);
    // Update breadcrumbs when clicking a breadcrumb
    const newBreadcrumbs = [
      { name: 'Home', path: [] },
      ...path.map((name, index) => ({
        name,
        path: path.slice(0, index + 1),
      })),
    ];
    setBreadcrumbs(newBreadcrumbs);
  };

  // Add breadcrumb navigation
  const renderBreadcrumbs = () => (
    <nav className="flex mb-4" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {breadcrumbs.map((crumb, index) => (
          <li key={index} className="inline-flex items-center">
            {index > 0 && (
              <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            <button
              onClick={() => handleBreadcrumbClick(crumb.path)}
              className={`inline-flex items-center text-sm font-medium ${
                index === breadcrumbs.length - 1 ? 'text-gray-500 cursor-default' : 'text-[#2A9D8F] hover:text-[#2A9D8F]/80'
              }`}
            >
              {crumb.name}
            </button>
          </li>
        ))}
      </ol>
    </nav>
  );

  const getCurrentFolder = () => {
    if (currentPath.length === 0) return null;

    let current = documentStructure.find((item) => item.name === currentPath[0]);
    if (currentPath.length > 1) {
      for (let i = 1; i < currentPath.length; i++) {
        current = current?.children?.find((item) => item.name === currentPath[i]);
      }
    }
    return current;
  };

  const FolderGrid = () => {
    const content = getCurrentContent();

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {content.map((item) => (
          <div
            key={item.id}
            onClick={() => (item.isFolder ? handleFolderClick(item.name) : handleFileClick(item))}
            className="p-4 bg-white rounded-lg border border-gray-200 hover:border-[#2A9D8F] cursor-pointer"
          >
            <div className="flex items-center space-x-3">
              {item.isFolder ? <FolderIcon className="h-6 w-6 text-[#2A9D8F]" /> : <DocumentIcon document={item.data} />}
              <div>
                <h3 className="text-sm font-medium text-gray-900">{item.name}</h3>
                {item.isFolder ? (
                  <p className="text-xs text-gray-500">{(item.documents && item.documents.length) || 0} files</p>
                ) : (
                  <p className="text-xs text-gray-500">{formatDistanceToNow(new Date(item.data.uploadedDate), { addSuffix: true })}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleFileClick = (file) => {
    if (file.data?.signedUrl) {
      setSelectedDocument(file.data);
      setIsViewerOpen(true);
    }
  };

  const getFolderStats = (folder) => {
    if (!folder.children) return { documents: 0, subfolders: 0 };

    const stats = {
      documents: folder.children.filter((item) => item.type === 'file').length,
      subfolders: folder.children.filter((item) => item.type === 'folder').length,
    };

    // Recursively count documents in subfolders
    folder.children
      .filter((item) => item.type === 'folder')
      .forEach((subfolder) => {
        const subStats = getFolderStats(subfolder);
        stats.documents += subStats.documents;
        stats.subfolders += subStats.subfolders;
      });

    return stats;
  };

  const DocumentItem = ({ item, viewMode, onItemClick }) => {
    const folderStats = item.isFolder ? getFolderStats(item) : null;
    const isArchived = item.isFolder && item.name.toLowerCase() === 'archived';

    return (
      <div
        onClick={onItemClick}
        className={`${
          viewMode === 'grid'
            ? 'bg-white rounded-xl border border-gray-200 hover:border-[#2A9D8F] hover:shadow-lg transition-all duration-200'
            : 'bg-white hover:bg-gray-50 border-b border-gray-200'
        } ${isArchived ? 'opacity-75' : ''} cursor-pointer h-32 w-full flex items-center justify-between px-6 py-4 group relative`}
      >
        {/* Add star icon in absolute position */}
        {!item.isFolder && item.data?.isImportant && (
          <div className="absolute top-2 right-2">
            <StarIconSolid className="h-5 w-5 text-yellow-400" />
          </div>
        )}

        <div className="flex items-center space-x-4 min-w-0">
          <div className="flex-shrink-0">
            {item.isFolder ? (
              <div className={`w-16 h-16 rounded-xl flex items-center justify-center ${isArchived ? 'bg-gray-100' : 'bg-[#2A9D8F]/10'}`}>
                {isArchived ? (
                  <ArchiveBoxIcon className="h-8 w-8 text-gray-400" />
                ) : (
                  <FolderIcon className={`h-8 w-8 ${isArchived ? 'text-gray-400' : 'text-[#2A9D8F]'}`} />
                )}
              </div>
            ) : (
              <div className="w-16 h-16 rounded-xl bg-gray-100 flex items-center justify-center">
                <DocumentIcon document={item.data} />
              </div>
            )}
          </div>

          <div className="min-w-0 flex-1">
            <div className="flex items-center space-x-2">
              <h3 className={`text-sm font-medium truncate ${isArchived ? 'text-gray-500' : 'text-gray-900'}`}>{item.name}</h3>
              {!item.isFolder && (
                <span
                  className={`px-2 py-1 rounded-full text-xs font-medium ${
                    item.data?.approvedByClient ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'
                  }`}
                >
                  {item.data?.approvedByClient ? 'Approved' : 'Approval Required'}
                </span>
              )}
            </div>
            {item.isFolder ? (
              <div className="flex items-center space-x-3 mt-1">
                <p className={`text-xs ${isArchived ? 'text-gray-400' : 'text-gray-500'}`}>
                  {folderStats.documents} {folderStats.documents === 1 ? 'document' : 'documents'}
                </p>
                {folderStats.subfolders > 0 && (
                  <>
                    <span className="text-xs text-gray-300">•</span>
                    <p className={`text-xs ${isArchived ? 'text-gray-400' : 'text-gray-500'}`}>
                      {folderStats.subfolders} {folderStats.subfolders === 1 ? 'subfolder' : 'subfolders'}
                    </p>
                  </>
                )}
                {isArchived && (
                  <>
                    <span className="text-xs text-gray-300">•</span>
                    <p className="text-xs text-gray-400">Archived</p>
                  </>
                )}
              </div>
            ) : (
              <div className="flex items-center space-x-3 mt-1">
                <p className="text-xs text-gray-500">{formatDistanceToNow(new Date(item.data.uploadedDate), { addSuffix: true })}</p>
                {item.data.version && (
                  <>
                    <span className="text-xs text-gray-300">•</span>
                    <p className="text-xs text-gray-500">Version {item.data.version}</p>
                  </>
                )}
                {item.data.size && (
                  <>
                    <span className="text-xs text-gray-300">•</span>
                    <p className="text-xs text-gray-500">{typeof item.data.size === 'number' ? `${Math.round(item.data.size / 1024)} KB` : item.data.size}</p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const DocumentList = () => {
    const content = getSortedAndFilteredContent();

    if (content.length === 0) {
      if (filterText) {
        return (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="flex flex-col items-center justify-center py-16 px-4 text-center">
            <div className="mb-4 p-3 bg-gray-100 rounded-full">
              <FunnelIcon className="h-8 w-8 text-gray-400" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">No matching documents</h3>
            <p className="text-gray-500 max-w-md">We couldn't find any documents matching "{filterText}". Try adjusting your search terms.</p>
          </motion.div>
        );
      }

      return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <EmptyState
            currentPath={currentPath}
            setIsUploadModalOpen={setIsUploadModalOpen}
            setIsNewFolderModalOpen={setIsNewFolderModalOpen}
            isInSubCategory={isInSubCategory}
          />
        </motion.div>
      );
    }

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className={viewMode === 'grid' ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4' : 'space-y-2'}
      >
        {content.map((item, index) => (
          <motion.div key={item.id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.05 }}>
            <DocumentItem item={item} viewMode={viewMode} onItemClick={() => (item.isFolder ? handleFolderClick(item.name) : handleFileClick(item))} />
          </motion.div>
        ))}
      </motion.div>
    );
  };

  const handleDocumentSelect = (docId, event) => {
    if (event.ctrlKey || event.metaKey) {
      setSelectedDocs((prev) => (prev.includes(docId) ? prev.filter((id) => id !== docId) : [...prev, docId]));
    } else {
      setSelectedDocs([docId]);
    }
  };

  const handleContextMenu = (event, doc) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      doc,
    });
  };

  // Add cleanup for context menu
  useEffect(() => {
    const handleClickOutside = () => setContextMenu(null);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const getFileIcon = (fileName) => {
    if (fileName.endsWith('.pdf')) return '📄';
    if (fileName.endsWith('.doc') || fileName.endsWith('.docx')) return '📝';
    if (fileName.endsWith('.jpg') || fileName.endsWith('.png')) return '🖼️';
    if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) return '📊';
    return '📎';
  };

  const getFileSize = (size) => {
    const units = ['B', 'KB', 'MB', 'GB'];
    let fileSize = size;
    let unitIndex = 0;

    while (fileSize >= 1024 && unitIndex < units.length - 1) {
      fileSize /= 1024;
      unitIndex++;
    }

    return `${fileSize.toFixed(1)} ${units[unitIndex]}`;
  };

  // Add folder modal component
  const renderNewFolderModal = () => (
    <Transition.Root show={isNewFolderModalOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={handleCloseModal}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Create New {currentPath.length > 0 ? 'Subfolder' : 'Folder'}
                </Dialog.Title>
                {currentPath.length > 0 && <p className="mt-2 text-sm text-gray-500">Creating subfolder in: {currentPath.join(' / ')}</p>}
                <div className="mt-4">
                  <input
                    type="text"
                    value={newFolderName}
                    onChange={(e) => setNewFolderName(e.target.value)}
                    placeholder={`${currentPath.length > 0 ? 'Subfolder' : 'Folder'} name`}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#2A9D8F]"
                  />
                </div>
                <div className="mt-4">
                  <p className="text-sm text-gray-500">
                    Please add at least one document to the folder after it is created. This ensures that the folder can be saved.
                  </p>
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={handleCloseModal} // Use handleCloseModal here
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    onClick={handleCreateFolder}
                    disabled={isCreatingFolder || !newFolderName.trim()}
                    className="px-4 py-2 text-sm font-medium text-white bg-[#2A9D8F] rounded-md hover:bg-[#2A9D8F]/90 disabled:opacity-50"
                  >
                    {isCreatingFolder ? 'Creating...' : 'Create'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  // Add a function to check if we're in a subcategory
  const isInSubCategory = () => {
    return currentPath.length > 1;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('category', currentPath[0] || ''); // First part of path is category
    formData.append('subCategory', currentPath[1] || ''); // Second part is subCategory
    formData.append('visibleToClient', 'true');

    try {
      await documentService.uploadDocument(projectId, formData);
      // Refresh documents list
      const updatedDocs = await documentService.getDocuments(projectId);
      setDocuments(updatedDocs);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  // Function to sort and filter content
  const getSortedAndFilteredContent = () => {
    let content = getCurrentContent();

    // Filter content
    if (filterText) {
      const searchTerm = filterText.toLowerCase();

      // Search through all documents regardless of current path
      const matchingDocs = documents
        .filter((doc) => doc.name.toLowerCase().includes(searchTerm))
        .map((doc) => ({
          id: doc._id,
          name: doc.name,
          type: 'file',
          data: doc,
          isFolder: false,
          path: doc.category ? [doc.category, doc.subCategory].filter(Boolean) : [],
        }));

      // Show only matching documents, no folder structure
      return matchingDocs.sort((a, b) => {
        if (sortBy === 'name') {
          const comparison = a.name.localeCompare(b.name);
          return sortOrder === 'asc' ? comparison : -comparison;
        } else if (sortBy === 'uploadedDate') {
          const dateA = new Date(a.data.uploadedDate);
          const dateB = new Date(b.data.uploadedDate);
          return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        }
        return 0;
      });
    }

    // If no search term, show normal folder structure with special sorting
    return content.sort((a, b) => {
      // Special case: if either item is the Archived folder
      if (a.isFolder && a.name.toLowerCase() === 'archived') return 1;
      if (b.isFolder && b.name.toLowerCase() === 'archived') return -1;

      // Normal sorting logic
      if (a.isFolder && !b.isFolder) return -1;
      if (!a.isFolder && b.isFolder) return 1;

      if (sortBy === 'name') {
        const comparison = a.name.localeCompare(b.name);
        return sortOrder === 'asc' ? comparison : -comparison;
      } else if (sortBy === 'uploadedDate') {
        const dateA = a.data?.uploadedDate ? new Date(a.data.uploadedDate) : new Date(0);
        const dateB = b.data?.uploadedDate ? new Date(b.data.uploadedDate) : new Date(0);
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      }
      return 0;
    });
  };

  // Update the toolbar section to include search
  const renderToolbar = () => (
    <div className="flex flex-col space-y-4 w-full">
      {/* Primary actions row */}
      <div className="flex flex-col lg:flex-row lg:items-center space-y-3 lg:space-y-0 w-full">
        {/* Left section: Upload and New Folder buttons */}
        <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-2 lg:mr-3">
          <button
            onClick={() => setIsUploadModalOpen(true)}
            disabled={isUploading}
            className="inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium text-white bg-[#2A9D8F] rounded-lg hover:bg-[#2A9D8F]/90 transition-colors w-full sm:w-auto whitespace-nowrap"
          >
            <DocumentHeroIcon className="h-5 w-5 mr-2" />
            {isUploading ? 'Uploading...' : 'Upload File'}
          </button>
          {!isInSubCategory() && (
            <button
              onClick={() => setIsNewFolderModalOpen(true)}
              className="inline-flex items-center justify-center px-4 py-2.5 text-sm font-medium text-[#2A9D8F] bg-white rounded-lg border border-[#2A9D8F] hover:bg-[#2A9D8F] hover:text-white transition-colors w-full sm:w-auto whitespace-nowrap"
            >
              <FolderIcon className="h-5 w-5 mr-2" />
              New Folder
            </button>
          )}
        </div>

        {/* Center section: Search input */}
        <div className="relative flex-1 lg:max-w-xl">
          <input
            type="text"
            placeholder="Search files..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className="w-full pl-10 pr-4 py-2.5 border border-gray-300 rounded-lg focus:ring-[#2A9D8F] focus:border-[#2A9D8F] sm:text-sm"
          />
          <FunnelIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
        </div>

        {/* Right section: View mode and sort controls */}
        <div className="flex items-center justify-between lg:justify-end lg:ml-3 space-x-4 pt-2 lg:pt-0 border-t lg:border-t-0 mt-2 lg:mt-0">
          <div className="flex items-center space-x-2">
            <button onClick={() => setViewMode('grid')} className={`p-2.5 rounded-lg ${viewMode === 'grid' ? 'bg-[#2A9D8F] text-white' : 'hover:bg-gray-100'}`}>
              <Squares2X2Icon className="h-5 w-5" />
            </button>
            <button onClick={() => setViewMode('list')} className={`p-2.5 rounded-lg ${viewMode === 'list' ? 'bg-[#2A9D8F] text-white' : 'hover:bg-gray-100'}`}>
              <ViewColumnsIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="flex items-center space-x-2">
            <select
              className="text-sm border-gray-300 rounded-lg focus:ring-[#2A9D8F] focus:border-[#2A9D8F] py-2.5 pr-8"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="uploadedDate">Date</option>
              <option value="name">Name</option>
            </select>
            <button onClick={() => setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))} className="p-2.5 hover:bg-gray-100 rounded-lg">
              {sortOrder === 'asc' ? <ArrowUpIcon className="h-5 w-5 text-gray-500" /> : <ArrowDownIcon className="h-5 w-5 text-gray-500" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // Add upload modal
  const renderUploadModal = () => (
    <Transition.Root show={isUploadModalOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={() => setIsUploadModalOpen(false)}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <UploadForm
                projectId={projectId}
                userRole={userRole}
                category={currentPath[0]}
                currentPath={currentPath}
                existingSubfolders={getExistingSubfolders()}
                onClose={() => setIsUploadModalOpen(false)}
                onUploadComplete={async () => {
                  const updatedDocs = await documentService.getDocuments(projectId);
                  setDocuments(updatedDocs);
                }}
              />
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  // Get existing subfolders for the current category
  const getExistingSubfolders = () => {
    if (!currentPath[0]) return [];
    return [...new Set(documents.filter((doc) => doc.category === currentPath[0]).map((doc) => doc.subCategory))];
  };

  const handleDocumentUpdate = (update) => {
    if (!update) return;

    if (update.type === 'delete') {
      // Filter out the deleted document using its ID
      setDocuments((docs) => docs.filter((doc) => doc._id !== update.documentId));
    } else if (Array.isArray(update)) {
      // Handle array of documents (full update)
      setDocuments(update);
    } else {
      // Handle single document updates
      setDocuments((docs) => docs.map((doc) => (doc._id === update._id ? update : doc)));
      if (selectedDocument?._id === update._id) {
        setSelectedDocument(update);
      }
    }
  };

  // Add handler for document selection in RecentDocuments
  const handleRecentDocumentSelect = (doc) => {
    setSelectedDocument(doc);
    setIsViewerOpen(true);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mb-6 space-y-4">
        {renderToolbar()}
        <div className="flex items-center justify-left space-x-4 pt-6">{renderBreadcrumbs()}</div>
      </div>

      {/* Add title for main documents section */}
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-900">{currentPath.length === 0 ? 'All Documents' : currentPath[currentPath.length - 1]}</h2>
        <p className="mt-1 text-sm text-gray-500">
          {currentPath.length === 0
            ? 'Browse and manage all your project documents. Documents with a star icon are important.'
            : `Viewing documents in ${currentPath.join(' / ')}`}
        </p>
      </div>

      <DocumentList />

      {currentPath.length === 0 && (
        <>
          <div className="my-8 border-t border-gray-200" />
          <div className="mb-4">
            <h2 className="text-xl font-semibold text-gray-900">Recently Added Documents</h2>
            <p className="mt-1 text-sm text-gray-500">Quick access to your most recently uploaded documents</p>
          </div>
          <RecentDocuments documents={documents} onDocumentSelect={handleRecentDocumentSelect} />

          <div className="my-8 border-t border-gray-200" />
          <DocumentTable documents={documents} onDocumentUpdate={handleDocumentUpdate} userRole={userRole} />
        </>
      )}

      {renderNewFolderModal()}
      {renderUploadModal()}

      <DocumentViewerModal
        document={selectedDocument}
        isOpen={isViewerOpen}
        onClose={() => {
          setIsViewerOpen(false);
          setSelectedDocument(null);
        }}
        onDocumentUpdate={handleDocumentUpdate}
      />
    </div>
  );
};

export default DocumentFolders;
