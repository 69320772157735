import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Info, FileText, AlertCircle, Clock, CheckCircle2, PencilRuler, GitPullRequest } from 'lucide-react';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import NotesTable from './Table';

function NotesSummaryV2({ notes, refetch }) {
  const { projectId } = useParams();
  const [isInfoExpanded, setIsInfoExpanded] = useState(false);

  const sections = [
    {
      title: 'Note Types',
      icon: <FileText className="w-5 h-5" />,
      content: [
        { label: 'General Notes', description: 'For project updates, meeting notes, and general information' },
        { label: 'Design Notes', description: 'For design decisions, revisions, and visual documentation' },
        { label: 'Change Requests', description: 'For tracking project modifications and client requests' },
        { label: 'Document Feedback', description: 'For feedback on documents, including approval or changes required' },
      ],
    },
    {
      title: 'Priority Levels',
      icon: <AlertCircle className="w-5 h-5" />,
      content: [
        { label: 'Urgent', description: 'Immediate attention required, critical for project progress' },
        { label: 'High', description: 'Important tasks that need attention soon' },
        { label: 'Normal', description: 'Standard priority for regular tasks' },
        { label: 'Low', description: 'Can be addressed when time permits' },
      ],
    },
    {
      title: 'Best Practices',
      icon: <CheckCircle2 className="w-5 h-5" />,
      content: [
        { label: 'Be Specific', description: 'Include relevant details and context in your notes' },
        { label: 'Link Documents', description: 'Attach relevant files to provide additional context' },
        { label: 'Update Status', description: 'Keep the note status current as work progresses' },
        { label: 'Add Action Items', description: 'Break down tasks into trackable action items' },
      ],
    },
  ];

  return (
    <div className="bg-white/10 backdrop-blur-xl rounded-2xl overflow-hidden border border-white/20 shadow-[0_8px_32px_0_rgba(31,38,135,0.15)] relative">
      <div className="relative px-6 py-8 backdrop-blur-md bg-white/30">
        <h3 className="text-2xl font-semibold bg-gradient-to-r from-[#2A9D8F] to-[#258B7A] bg-clip-text text-transparent">Project Notes</h3>
        <p className="mt-2 text-sm text-gray-500">Track and manage project communications and updates</p>
      </div>

      <div className="px-6 py-4 border-t border-white/10 backdrop-blur-md">
        <div className="flex items-center justify-between">
          <motion.button
            onClick={() => setIsInfoExpanded(!isInfoExpanded)}
            className="flex items-center gap-2 px-3 py-1.5 rounded-md text-sm text-[#2A9D8F] hover:bg-[#2A9D8F]/10 backdrop-blur-sm transition-all duration-300"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Info className="w-4 h-4" />
            <span>How to Use Project Notes</span>
          </motion.button>
        </div>

        <AnimatePresence>
          {isInfoExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="mt-4 overflow-hidden"
            >
              <div className="px-6 pb-6 grid gap-6 md:grid-cols-3 bg-white/20 backdrop-blur-md p-4 rounded-lg border border-white/20">
                {sections.map((section) => (
                  <div key={section.title} className="space-y-3">
                    <div className="flex items-center gap-2 text-[#2A9D8F]">
                      {section.icon}
                      <h3 className="font-medium">{section.title}</h3>
                    </div>
                    <div className="space-y-2">
                      {section.content.map((item) => (
                        <div key={item.label} className="space-y-1">
                          <div className="text-sm font-medium text-gray-700">{item.label}</div>
                          <div className="text-sm text-gray-500">{item.description}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className="border-t border-white/10 backdrop-blur-md">
        {notes.length > 0 ? (
          <NotesTable projectId={projectId} notes={notes} refetch={refetch} />
        ) : (
          <div className="text-center py-12 bg-white/20">
            <ChatBubbleLeftRightIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No project notes</h3>
            <p className="mt-1 text-sm text-gray-500">Notes for this project will appear here.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default NotesSummaryV2;
